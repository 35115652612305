define("shared/components/searchable-select/component", ["exports", "ui/utils/constants", "shared/components/searchable-select/template", "ui/utils/util", "jquery", "shared/utils/stateful-promise"], function (_exports, _constants, _template, _util, _jquery, _statefulPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_HEIGHT = 285;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['searchable-select'],
    classNameBindings: ['class', 'showDropdownArrow', 'asyncContent.loading:loading', 'asyncContent.loaded:loaded', 'asyncContent.error:error'],
    attributeBindings: ['data-testid'],
    // input
    class: null,
    value: null,
    prefix: null,
    suffix: null,
    prompt: null,
    placeholder: null,
    // If need to catch the group changes, you can pass a group prop in.
    group: null,
    content: null,
    interContent: null,
    optionLabelPath: 'label',
    optionValuePath: 'value',
    optionGroupPath: 'group',
    localizedPrompt: false,
    localizedLabel: false,
    localizedHtmlLabel: false,
    customLabel: false,
    readOnly: null,
    showOptions: false,
    allowCustom: false,
    filter: null,
    clientSideFiltering: true,
    // the current highlighted option.
    $activeTarget: null,
    maxHeight: MAX_HEIGHT,
    showDropdownArrow: true,

    init() {
      this._super(...arguments);

      this.observeContent();
    },

    didInsertElement() {
      const search = (0, _jquery.default)(this.element).find('.input-search');
      search.attr('autocomplete', 'off');
      search.on('click', () => {
        this.send('show');
      });
    },

    willDestroyElement() {
      this.off();
    },

    actions: {
      search()
      /* term*/
      {// placeholder is over written by extenders if you want
      },

      selectUnGroupedItem(idx) {
        const found = Ember.get(this, 'unGroupedContent').objectAt(idx);
        this.setSelect(found);
      },

      selectGroupedItem(items, idx) {
        const found = items.objectAt(idx);
        this.setSelect(found);
      },

      selectPrompt() {
        Ember.set(this, 'value', null);
        this.send('hide');
      },

      mouseEnter(event) {
        (0, _jquery.default)(this.element).find('.searchable-option').removeClass('searchable-option-active');
        const $target = (0, _jquery.default)(event.target);
        $target.addClass('searchable-option-active');
        Ember.set(this, '$activeTarget', $target);
      },

      mouseLeave(event) {
        (0, _jquery.default)(event.target).removeClass('searchable-option-active');
        Ember.set(this, '$activeTarget', null);
      },

      show() {
        if (Ember.get(this, 'showOptions') === true) {
          return;
        }

        const toBottom = (0, _jquery.default)('body').height() - (0, _jquery.default)(this.element).offset().top - 60; // eslint-disable-line

        Ember.set(this, 'maxHeight', toBottom < MAX_HEIGHT ? toBottom : MAX_HEIGHT);
        Ember.set(this, 'filter', null);
        Ember.run.next(() => {
          const checked = (0, _jquery.default)(this.element).find('.searchable-option .icon-check');
          const options = (0, _jquery.default)(this.element).find('.searchable-options');

          if (options.length && checked.length) {
            options.animate({
              scrollTop: `${checked.parent().offset().top - options.offset().top}px`
            });
          }
        });
        Ember.set(this, 'showOptions', true);
      },

      hide() {
        Ember.set(this, 'filter', Ember.get(this, 'displayLabel'));
        Ember.set(this, 'showOptions', false);
        Ember.set(this, '$activeTarget', null);
      }

    },
    observeContent: Ember.observer('asyncContent.value.[]', 'value', 'displayLabel', function () {
      let asyncContentValue = Ember.get(this, 'asyncContent.value');

      if (asyncContentValue) {
        Ember.set(this, 'interContent', asyncContentValue.slice(0));
      }

      if (Ember.get(this, 'allowCustom')) {
        Ember.set(this, 'searchLabel', 'generic.searchOrCustomInput');
        const value = Ember.get(this, 'value');
        this.insertCustomValue(value, false);
      }

      Ember.set(this, 'filter', Ember.get(this, 'displayLabel'));
    }),
    asyncContent: Ember.computed('content', function () {
      return _statefulPromise.default.wrap(Ember.get(this, 'content'), []);
    }),
    optionsMaxHeightCss: Ember.computed('maxHeight', function () {
      return Ember.String.htmlSafe(`max-height: ${Ember.get(this, 'maxHeight')}px`);
    }),
    // Show option image --> unGroupedContent only
    showOptionIcon: Ember.computed('unGroupedContent.@each.imgUrl', function () {
      return Ember.get(this, 'unGroupedContent').some(item => !!item.imgUrl);
    }),
    displayLabel: Ember.computed('interContent.[]', 'intl.locale.[]', 'localizedHtmlLabel', 'localizedLabel', 'optionLabelPath', 'optionValuePath', 'prompt', 'value', function () {
      const value = Ember.get(this, 'value');
      const vp = Ember.get(this, 'optionValuePath');
      const lp = Ember.get(this, 'optionLabelPath');
      const selectedItem = Ember.get(this, 'interContent').filterBy(vp, value).get('firstObject');

      if (selectedItem) {
        let label = Ember.get(selectedItem, lp);

        if (Ember.get(this, 'localizedLabel')) {
          label = Ember.get(this, 'intl').t(label);
        } else if (Ember.get(this, 'localizedHtmlLabel')) {
          label = value;
        }

        return label;
      }

      return null;
    }),
    filtered: Ember.computed('allowCustom', 'clientSideFiltering', 'filter', 'interContent.[]', 'optionGroupPath', 'optionLabelPath', function () {
      const filter = (Ember.get(this, 'filter') || '').trim();
      const options = Ember.get(this, 'interContent');

      if (Ember.get(this, 'allowCustom')) {
        this.insertCustomValue(filter, true);
      }

      if (Ember.get(this, 'clientSideFiltering')) {
        const filteredOptionsA = [];
        const filteredOptionsB = [];
        options.forEach(option => {
          const filterTerms = filter.split(/\s+/);
          const gp = Ember.get(this, 'optionGroupPath');
          const lp = Ember.get(this, 'optionLabelPath');
          const group = Ember.get(option, gp);
          const label = Ember.get(option, lp);
          let startsWithOneOfFilterTerm = false;
          let containsEveryFilterTerm = true;
          filterTerms.forEach(s => {
            s = s.toLowerCase();
            const startsWith = label.toLowerCase().startsWith(s) || group && group.toLowerCase().startsWith(s);

            if (startsWith) {
              startsWithOneOfFilterTerm = true;
            }

            const exp = (0, _util.escapeRegex)(s);
            const pattern = new RegExp(exp, 'i');
            const contains = pattern.test(label) || group && pattern.test(group);

            if (!contains) {
              containsEveryFilterTerm = false;
            }
          });

          if (startsWithOneOfFilterTerm && containsEveryFilterTerm) {
            filteredOptionsA.push(option);
            return;
          }

          if (containsEveryFilterTerm) {
            filteredOptionsB.push(option);
            return;
          }
        });
        return filteredOptionsA.concat(filteredOptionsB);
      } else {
        return options;
      }
    }),
    unGroupedContent: Ember.computed('filtered.[]', 'optionGroupPath', function () {
      const groupPath = Ember.get(this, 'optionGroupPath');
      const out = [];
      Ember.get(this, 'filtered').forEach(opt => {
        const key = Ember.get(opt, groupPath);

        if (!key) {
          out.push(opt);
        }
      });
      return out;
    }),
    groupedContent: Ember.computed('filtered.[]', 'optionGroupPath', function () {
      const groupPath = Ember.get(this, 'optionGroupPath');
      const out = [];
      Ember.get(this, 'filtered').forEach(opt => {
        const key = Ember.get(opt, groupPath);

        if (key) {
          let group = out.filterBy('group', key)[0];
          let groupLabel = this.intl.exists(key) ? this.intl.t(key) : key;

          if (!group) {
            group = {
              group: groupLabel,
              options: []
            };
            out.push(group);
          }

          group.options.push(opt);
        }
      });
      return out.sortBy(groupPath);
    }),
    showMessage: Ember.computed('filtered.[]', function () {
      return Ember.get(this, 'filtered.length') === 0;
    }),
    missingMessage: Ember.computed('asyncContent.value.[]', function () {
      let len = Ember.get(this, 'asyncContent.value.length');
      let out = 'searchableSelect.noOptions';

      if (len) {
        out = 'searchableSelect.noMatch';
      }

      return out;
    }),
    showOptionsChanged: Ember.on('init', Ember.observer('showOptions', function () {
      const show = Ember.get(this, 'showOptions');

      if (show) {
        this.on();
      } else {
        this.off();
      }
    })),

    allContent() {
      const out = [];
      const grouped = Ember.get(this, 'groupedContent');
      const unGrouped = Ember.get(this, 'unGroupedContent');
      out.pushObjects(unGrouped);
      grouped.forEach(g => out.pushObjects(g.options));
      return out;
    },

    on() {
      (0, _jquery.default)(this.element).on('keydown.searchable-option', event => {
        const kc = event.keyCode; // Note: keyup event can't be prevented.

        if (!Ember.get(this, 'showOptions')) {
          return;
        }

        if (kc === _constants.default.KEY.UP) {
          this.stepThroughOptions(-1);
        }

        if (kc === _constants.default.KEY.DOWN) {
          this.stepThroughOptions(1);
        } // support using return key to select the current active option


        if (kc === _constants.default.KEY.CR || kc === _constants.default.KEY.LF) {
          event.preventDefault();
          let $activeTarget = Ember.get(this, '$activeTarget');

          if (!$activeTarget) {
            $activeTarget = this.$(this.element).find('.searchable-options > .searchable-option:first-child');
          }

          if ($activeTarget) {
            // activeTarget is prompt
            if ($activeTarget.hasClass('searchable-prompt')) {
              this.send('selectPrompt');
            } else {
              let idx = (0, _jquery.default)(this.element).find('.searchable-option').index($activeTarget);
              idx = !!Ember.get(this, 'prompt') ? idx - 1 : idx; // set value

              const activeOption = this.allContent().objectAt(idx);
              this.setSelect(activeOption);
            } // hide options after value has been set


            this.send('hide');
          }
        } // esc to hide


        if (kc === _constants.default.KEY.ESCAPE) {
          this.send('hide');
        }
      });
    },

    off() {
      if ((0, _jquery.default)(this.element)) {
        (0, _jquery.default)(this.element).off('keydown.searchable-option');
      }
    },

    setSelect(item) {
      const gp = Ember.get(this, 'optionGroupPath');
      const vp = Ember.get(this, 'optionValuePath');
      Ember.set(this, 'value', Ember.get(item, vp));

      if (gp && Ember.get(item, gp)) {
        Ember.set(this, 'group', Ember.get(item, gp));
      }

      Ember.set(this, 'filter', Ember.get(this, 'displayLabel')); // https://stackoverflow.com/questions/39624902/new-input-placeholder-behavior-in-safari-10-no-longer-hides-on-change-via-java

      Ember.run.next(() => {
        const input = (0, _jquery.default)(this.element).find('.input-search');

        if (input) {
          input.focus();
          input.blur();
        }
      });

      if (this.change) {
        this.change(item);
      }

      this.send('hide');
    },

    stepThroughOptions(step) {
      const $activeTarget = Ember.get(this, '$activeTarget');
      const $options = (0, _jquery.default)(this.element).find('.searchable-option');
      const len = $options.length;
      let currentIdx = -1;
      let nextIdx = 0;

      if (len === 0) {
        return;
      }

      if (!$activeTarget) {
        $options.removeClass('searchable-option-active');
        $options.eq(0).addClass('searchable-option-active');
        Ember.set(this, '$activeTarget', $options.eq(0));
        return;
      }

      currentIdx = $options.index($activeTarget);

      if (currentIdx !== -1) {
        nextIdx = currentIdx + step;
      }

      if (nextIdx !== 0) {
        nextIdx = nextIdx < 0 ? len - 1 : nextIdx % len;
      }

      const $nextActiveTarget = $options.eq(nextIdx);
      Ember.set(this, '$activeTarget', $nextActiveTarget);
      $activeTarget.removeClass('searchable-option-active');
      $nextActiveTarget.addClass('searchable-option-active');
    },

    insertCustomValue(value, isFilter) {
      const vp = Ember.get(this, 'optionValuePath');
      const lp = Ember.get(this, 'optionLabelPath');
      value = value || '';

      if (!isFilter) {
        const custom = {
          custom: true
        };
        custom[lp] = `${value} (Custom)`;
        custom[vp] = value;
        Ember.get(this, 'interContent').pushObject(custom);
      } else {
        const found = Ember.get(this, 'interContent').filterBy('custom', true).get('firstObject');

        if (found) {
          Ember.set(found, lp, `${value} (Custom)`);
          Ember.set(found, vp, value);
        }
      }
    }

  });

  _exports.default = _default;
});