define("shared/components/modal-confirm-deactivate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z9ggyHzv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"protip\"],[12],[1,[30,[36,0],[\"modalConfirmDeactivate.protip\"],[[\"key\",\"isServiceButton\"],[[35,3],[35,2,[\"button\"]]]]]],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mb-20 mt-20\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"color\"],[\"bg-warning m-0\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"modalConfirmDeactivate.clusterDriver\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mb-20 mt-20\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"color\"],[\"bg-warning m-0\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"modalConfirmDeactivate.nodeDriver\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container-header-text\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"modalConfirmDeactivate.header\"],null]],[2,\" \"],[1,[35,2,[\"message\"]]],[2,\":\\n  \"],[10,\"div\"],[14,0,\"display-name\"],[12],[2,\"\\n    \"],[1,[34,7]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-error\"],[24,4,\"button\"],[4,[38,8],[[32,0],\"confirm\"],null],[12],[1,[35,2,[\"button\"]]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-transparent\"],[24,4,\"button\"],[4,[38,8],[[32,0],\"cancel\"],null],[12],[1,[30,[36,0],[\"modalConfirmDeactivate.cancel\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"banner-message\",\"isService\",\"alternateLabel\",\"isClusterDriver\",\"if\",\"isNodeDriver\",\"list\",\"action\"]}",
    "moduleName": "shared/components/modal-confirm-deactivate/template.hbs"
  });

  _exports.default = _default;
});