define("shared/utils/oci", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OCI_REGIONS = void 0;
  // Copyright 2020 Oracle and/or its affiliates.
  // See:
  //   https://docs.cloud.oracle.com/en-us/iaas/Content/General/Concepts/regions.htm
  //   https://docs.cloud.oracle.com/en-us/iaas/Content/General/Concepts/govfeddod.htm
  //   https://docs.cloud.oracle.com/en-us/iaas/Content/General/Concepts/govuksouth.htm
  const OCI_REGIONS = ['af-johannesburg-1', 'ap-chuncheon-1', 'ap-hyderabad-1', 'ap-melbourne-1', 'ap-mumbai-1', 'ap-osaka-1', 'ap-seoul-1', 'ap-singapore-1', 'ap-sydney-1', 'ap-tokyo-1', 'ca-montreal-1', 'ca-toronto-1', 'eu-amsterdam-1', 'eu-frankfurt-1', 'eu-madrid-1', 'eu-marseille-1', 'eu-milan-1', 'eu-paris-1', 'eu-stockholm-1', 'eu-zurich-1', 'il-jerusalem-1', 'me-abudhabi-1', 'me-dubai-1', 'me-jeddah-1', 'mx-queretaro-1', 'sa-santiago-1', 'sa-saopaulo-1', 'sa-vinhedo-1', 'uk-cardiff-1', 'uk-london-1', 'us-ashburn-1', 'us-chicago-1', 'us-phoenix-1', 'us-sanjose-1', 'us-langley-1', 'us-luke-1', 'us-gov-ashburn-1', 'us-gov-phoenix-1', 'us-gov-chicago-1', 'uk-gov-london-1', 'uk-gov-cardiff-1'];
  _exports.OCI_REGIONS = OCI_REGIONS;
});