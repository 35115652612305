define("shared/mixins/new-or-edit", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/errors"], function (_exports, _resource, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    originalModel: null,
    errors: null,
    editing: true,
    primaryResource: Ember.computed.alias('model'),
    originalPrimaryResource: Ember.computed.alias('originalModel'),
    tagName: 'form',

    // This indirectly disables global navigation shortcut keys
    init() {
      this._super(...arguments);

      Ember.set(this, 'errors', null);
    },

    validate() {
      var model = Ember.get(this, 'primaryResource');
      var errors = model.validationErrors(['appliedSpec']);

      if (errors.get('length')) {
        Ember.set(this, 'errors', errors);
        return false;
      }

      Ember.set(this, 'errors', null);
      return true;
    },

    submit(event) {
      event.preventDefault();
      this.send('save');
    },

    actions: {
      error(err) {
        if (err) {
          var body = _errors.default.stringify(err);

          Ember.set(this, 'errors', [body]);
        } else {
          Ember.set(this, 'errors', null);
        }
      },

      save(cb) {
        cb = cb || function () {}; // Will save can return true/false or a promise


        Ember.RSVP.resolve(this.willSave()).then(ok => {
          if (!ok) {
            // Validation or something else said not to save
            cb(false);
            return;
          }

          this.doSave().then(this.didSave.bind(this)).then(this.doneSaving.bind(this)).then(() => {
            cb(true);
          }).catch(err => {
            if (this.isDestroyed || this.isDestroying) {
              return;
            }

            this.send('error', err);
            this.errorSaving(err);
            cb(false);
          });
        });
      }

    },

    // willSave happens before save and can stop the save from happening
    willSave() {
      Ember.set(this, 'errors', null);
      var ok = this.validate();
      return ok;
    },

    doSave(opt) {
      // Pass this in case we need to check the previous model when saving
      return Ember.get(this, 'primaryResource').save(opt, this).then(newData => {
        return this.mergeResult(newData);
      });
    },

    mergeResult(newData) {
      var original = Ember.get(this, 'originalPrimaryResource');

      if (original) {
        if (_resource.default.detectInstance(original)) {
          original.merge(newData);
          return original;
        }
      }

      return newData;
    },

    // didSave can be used to do additional saving of dependent resources
    didSave(neu) {
      return neu;
    },

    // doneSaving happens after didSave
    doneSaving(neu) {
      return neu || Ember.get(this, 'originalPrimaryResource') || Ember.get(this, 'primaryResource');
    },

    // errorSaving can be used to do additional cleanup of dependent resources on failure
    errorSaving()
    /* err*/
    {}

  });

  _exports.default = _default;
});