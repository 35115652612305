define("shared/components/cluster-driver/driver-import-aks/component", ["exports", "shared/mixins/cluster-driver", "shared/utils/util", "shared/components/cluster-driver/driver-import-aks/template"], function (_exports, _clusterDriver, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'aksConfig',
    step: 1,
    loading: false,
    nodeForInfo: null,
    loadingClusters: false,
    errors: null,
    otherErrors: null,
    clusterErrors: null,
    selectedCred: null,
    isPostSave: false,
    regions: null,
    rbacEnabled: true,
    isEdit: Ember.computed.equal('mode', 'edit'),
    isNew: Ember.computed.equal('mode', 'new'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),
    allErrors: Ember.computed.union('errors', 'otherErrors', 'clusterErrors'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        errors: [],
        clusterErrors: [],
        otherErrors: []
      });

      if (this.isEdit) {
        const aksConfig = Ember.get(this, 'model.cluster.aksConfig');
        const cloudCredId = Ember.get(aksConfig, 'azureCredentialSecret');
        const tenantId = Ember.get(aksConfig, 'tenantId');
        const cloudCred = (this.model.cloudCredentials || []).find(cc => cc.id === cloudCredId);

        if (!Ember.isEmpty(cloudCred) && !Ember.isEmpty(tenantId)) {
          Ember.run.next(() => {
            this.send('finishAndSelectCloudCredential', cloudCred);
          });
        }
      } else {
        this.bootstrapAksV2Cluster();
      }
    },

    actions: {
      clusterSet(cluster) {
        if (!Ember.isEmpty(cluster === null || cluster === void 0 ? void 0 : cluster.resourceGroup)) {
          Ember.set(this, 'config.resourceGroup', cluster.resourceGroup);
        }

        if (!Ember.isEmpty(cluster === null || cluster === void 0 ? void 0 : cluster.rbacEnabled)) {
          Ember.set(this, 'rbacEnabled', cluster.rbacEnabled);
        }

        Ember.set(this, 'config.resourceLocation', cluster.resourceLocation);
      },

      finishAndSelectCloudCredential(cred) {
        if (Ember.isEmpty(cred)) {
          Ember.set(this, 'config.azureCredentialSecret', null);
          Ember.set(this, 'selectedCred', null);
        } else {
          Ember.set(this, 'config.azureCredentialSecret', cred.id);
          Ember.set(this, 'selectedCred', cred);
          this.send('fetchAksResources', (ok, err) => {
            if (!ok) {
              this.send('errorHandler', err);
            }
          });
        }
      },

      async fetchAksResources(cb) {
        const url = (0, _util.addQueryParams)('/meta/aksClusters', {
          cloudCredentialId: this.config.azureCredentialSecret
        });
        const errors = [];
        let step = 2;
        let allClusters;
        Ember.set(this, 'loadingClusters', true);

        try {
          var _allClusters;

          allClusters = await this.globalStore.rawRequest({
            url,
            method: 'GET'
          });
          allClusters = (((_allClusters = allClusters) === null || _allClusters === void 0 ? void 0 : _allClusters.body) || []).map(c => {
            return {
              label: c === null || c === void 0 ? void 0 : c.clusterName,
              value: c === null || c === void 0 ? void 0 : c.clusterName,
              resourceGroup: c === null || c === void 0 ? void 0 : c.resourceGroup,
              rbacEnabled: c === null || c === void 0 ? void 0 : c.rbacEnabled,
              resourceLocation: c === null || c === void 0 ? void 0 : c.location
            };
          }), Ember.setProperties(this, {
            allClusters,
            step
          });

          if (cb) {
            cb(true);
          }
        } catch (err) {
          errors.pushObject(`Failed to load Clusters from Azure: ${err.message}`); // Azure List Clusters API fails sometimes to list this, user cnn input a cluster name though so dont fail

          Ember.setProperties(this, {
            loadFailedAllClusters: true,
            errors
          });

          if (cb) {
            cb(false, err);
          }
        } finally {
          Ember.setProperties(this, {
            loadingClusters: false,
            step
          });
        }
      }

    },
    regionOrCredentialChanged: Ember.on('init', Ember.observer('config.{resourceLocation,azureCredentialSecret}', function () {
      const {
        config: {
          resourceLocation,
          azureCredentialSecret,
          tenantId
        },
        loadingClusters,
        errors
      } = this;

      if (errors && errors.length >= 1) {
        Ember.setProperties(this, {
          loadFailedAllClusters: false,
          errors: []
        });
      }

      if (!Ember.isEmpty(resourceLocation) && !Ember.isEmpty(azureCredentialSecret) && !Ember.isEmpty(tenantId) && !loadingClusters) {
        this.send('fetchAksResources');
      }
    })),
    cloudCredentials: Ember.computed('model.cloudCredentials.[]', function () {
      const {
        model: {
          cloudCredentials
        }
      } = this;
      return cloudCredentials.filter(cc => !Ember.isEmpty(Ember.get(cc, 'azurecredentialConfig')));
    }),
    disableImport: Ember.computed('step', 'config.{azureCredentialSecret,clusterName,resourceGroup}', function () {
      const {
        step,
        config: {
          azureCredentialSecret,
          clusterName,
          resourceGroup
        }
      } = this;

      if (step <= 2 && !Ember.isEmpty(azureCredentialSecret) && !Ember.isEmpty(clusterName) && !Ember.isEmpty(resourceGroup)) {
        return false;
      }

      return true;
    }),

    doneSaving() {
      const {
        isPostSave,
        model: {
          cluster: {
            aksConfig = {},
            aksStatus = {}
          }
        }
      } = this;
      const privateAccess = !Ember.get(aksStatus, 'aksConfig.privateCluster') || !Ember.get(aksConfig, 'privateCluster') || false;

      if (isPostSave && privateAccess) {
        Ember.set(this, 'step', 3);
        return;
      }

      if (this.close) {
        this.close();
      }
    },

    bootstrapAksV2Cluster() {
      const aksConfig = this.globalStore.createRecord({
        azureCredentialSecret: '',
        clusterName: '',
        imported: true,
        resourceGroup: '',
        resourceLocation: 'eastus',
        tenantId: '',
        type: 'aksclusterconfigspec'
      });
      Ember.set(this, 'model.cluster.aksConfig', aksConfig);
      Ember.set(this, 'config', aksConfig);
    }

  });

  _exports.default = _default;
});