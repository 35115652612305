define("global-admin/cluster-templates/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E16F59SU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[1,[35,0,[\"clusterTemplate\",\"displayName\"]]],[2,\": \"],[1,[35,0,[\"clusterTemplateRevision\",\"displayName\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[8,\"action-menu\",[[24,0,\"pull-right\"]],[[\"@size\",\"@model\"],[\"sm\",[34,0,[\"clusterTemplateRevision\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"cru-cluster-template\",[],[[\"@clusterTemplate\",\"@clusterTemplateRevision\",\"@clusterTemplateRevisionId\",\"@psps\",\"@psacs\",\"@mode\",\"@cancel\",\"@done\"],[[34,0,[\"clusterTemplate\"]],[34,0,[\"clusterTemplateRevision\"]],[34,0,[\"clusterTemplateRevisionId\"]],[34,0,[\"psps\"]],[34,0,[\"psacs\"]],\"view\",[30,[36,1],[[32,0],\"cancel\"],null],[30,[36,1],[[32,0],\"done\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\"]}",
    "moduleName": "global-admin/cluster-templates/detail/template.hbs"
  });

  _exports.default = _default;
});