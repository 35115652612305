define("shared/components/aks-node-pool-row/component", ["exports", "semver", "shared/components/aks-node-pool-row/template"], function (_exports, _semver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OS_TYPES = [{
    value: 'Linux'
  }, {
    value: 'Windows'
  }];
  const OS_DISK_TYPES = [{
    value: 'Ephemeral'
  }, {
    value: 'Managed'
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    cluster: null,
    nodePool: null,
    vmSizes: null,
    vmOs: OS_TYPES,
    diskTypes: OS_DISK_TYPES,
    upgradeVersion: false,
    showNodeUpgradePreventionReason: false,
    disableAzs: false,
    hasLabels: false,
    taints: {},
    hasTaints: false,
    isSystemType: Ember.computed.equal('nodePool.mode', 'System'),

    init() {
      this._super(...arguments);

      const taints = Ember.get(this, 'nodePool.nodeTaints');

      if (taints) {
        let _taints = taints.map((t = '') => {
          const splitEffect = t.split(':');
          const splitLabel = (splitEffect[0] || '').split('=');
          return {
            effect: splitEffect[1],
            key: splitLabel[0],
            value: splitLabel[1]
          };
        });

        this.taints = {
          nodeTaints: _taints
        };
        Ember.set(this, 'hasTaints', _taints.length > 0);
      } else {
        this.taints = {
          nodeTaints: []
        };
      }

      const labels = Ember.get(this, 'nodePool.nodeLabels');
      Ember.set(this, 'hasLabels', Object.keys(labels || {}).length > 0); // Ensure maxSurge is set - it may not be for an existing cluster created before the UI was updated to support maxSurge

      const maxSurge = Ember.get(this, 'nodePool.maxSurge');

      if (!maxSurge) {
        Ember.set(this, 'nodePool.maxSurge', 1);
      }
    },

    actions: {
      setNodeLabels(labels) {
        let out = {};
        labels.forEach(row => {
          out[row.key] = row.value;
        });
        Ember.set(this, 'nodePool.nodeLabels', out);
      },

      setTaints(taints) {
        if (taints.length > 0) {
          Ember.set(this, 'nodePool.nodeTaints', taints.map(t => {
            return `${t.key}=${t.value}:${t.effect}`;
          }));
        } else {
          Ember.set(this, 'nodePool.nodeTaints', []);
        }
      }

    },
    resetAutoScaling: Ember.observer('nodePool.enableAutoScaling', function () {
      const {
        nodePool
      } = this;

      if (!(nodePool !== null && nodePool !== void 0 && nodePool.enableAutoScaling)) {
        if (nodePool !== null && nodePool !== void 0 && nodePool.minCount) {
          delete nodePool.minCount;
        }

        if (nodePool !== null && nodePool !== void 0 && nodePool.maxCount) {
          delete nodePool.maxCount;
        }
      }
    }),
    updateNodeVersion: Ember.on('init', Ember.observer('cluster.aksConfig.kubernetesVersion', 'upgradeVersion', 'upgradeAvailable', 'isNewNodePool', function () {
      const {
        cluster,
        upgradeAvailable,
        nodePool,
        upgradeVersion,
        isNewNodePool
      } = this;

      if (upgradeAvailable && upgradeVersion || isNewNodePool) {
        Ember.set(nodePool, 'orchestratorVersion', cluster.aksConfig.kubernetesVersion);
      }
    })),
    resetAvailablity: Ember.on('init', Ember.observer('isNewNodePool', 'disableAzs', function () {
      const {
        isNewNodePool,
        disableAzs,
        nodePool: {
          availabilityZones
        },
        mode
      } = this;

      if (mode !== 'edit' && !isNewNodePool || disableAzs) {
        if (!Ember.isEmpty(availabilityZones)) {
          Ember.set(this, 'nodePool.availabilityZones', []);
        }
      }
    })),
    availablityZoneOne: Ember.computed('nodePool.availabilityZones.[]', {
      get() {
        var _this$nodePool;

        return (((_this$nodePool = this.nodePool) === null || _this$nodePool === void 0 ? void 0 : _this$nodePool.availabilityZones) ?? []).find(az => az === '1') ? true : false;
      },

      set(_key, value) {
        var _this$nodePool2;

        let azs = (((_this$nodePool2 = this.nodePool) === null || _this$nodePool2 === void 0 ? void 0 : _this$nodePool2.availabilityZones) ?? []).slice();

        if (value) {
          if (!azs.find(az => az === '1')) {
            azs.push('1');
          }
        } else {
          azs = azs.without('1');
        }

        Ember.set(this, 'nodePool.availabilityZones', azs.sort());
        return value;
      }

    }),
    availablityZoneThree: Ember.computed('nodePool.availabilityZones.[]', {
      get() {
        var _this$nodePool3;

        return (((_this$nodePool3 = this.nodePool) === null || _this$nodePool3 === void 0 ? void 0 : _this$nodePool3.availabilityZones) ?? []).find(az => az === '2') ? true : false;
      },

      set(_key, value) {
        var _this$nodePool4;

        let azs = (((_this$nodePool4 = this.nodePool) === null || _this$nodePool4 === void 0 ? void 0 : _this$nodePool4.availabilityZones) ?? []).slice();

        if (value) {
          if (!azs.find(az => az === '2')) {
            azs.push('2');
          }
        } else {
          azs = azs.without('2');
        }

        Ember.set(this, 'nodePool.availabilityZones', azs.sort());
        return value;
      }

    }),
    availablityZoneTwo: Ember.computed('nodePool.availabilityZones.[]', {
      get() {
        var _this$nodePool5;

        return (((_this$nodePool5 = this.nodePool) === null || _this$nodePool5 === void 0 ? void 0 : _this$nodePool5.availabilityZones) ?? []).find(az => az === '3') ? true : false;
      },

      set(_key, value) {
        var _this$nodePool6;

        let azs = (((_this$nodePool6 = this.nodePool) === null || _this$nodePool6 === void 0 ? void 0 : _this$nodePool6.availabilityZones) ?? []).slice();

        if (value) {
          if (!azs.find(az => az === '3')) {
            azs.push('3');
          }
        } else {
          azs = azs.without('3');
        }

        Ember.set(this, 'nodePool.availabilityZones', azs.sort());
        return value;
      }

    }),
    originalClusterVersion: Ember.computed('cluster.aksConfig.kubernetesVersion', 'originalCluster.aksConfig.kubernetesVersion', 'originalCluster.aksConfig.upstreamSpec.kubernetesVersion', function () {
      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.aksConfig.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.aksConfig.kubernetesVersion');
      }

      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.aksConfig.upstreamSpec.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.aksConfig.upstreamSpec.kubernetesVersion');
      }

      return '';
    }),
    upgradeAvailable: Ember.computed('cluster.aksConfig.kubernetesVersion', 'isNewNodePool', 'mode', 'model.version', 'nodePool.orchestratorVersion', 'originalClusterVersion', 'showNodeUpgradePreventionReason', function () {
      const originalClusterVersion = Ember.get(this, 'originalClusterVersion');
      const clusterVersion = Ember.get(this, 'cluster.aksConfig.kubernetesVersion');
      let nodeVersion = Ember.get(this, 'nodePool.orchestratorVersion');
      const mode = Ember.get(this, 'mode');
      const isNewNodePool = Ember.get(this, 'isNewNodePool');

      if (isNewNodePool && Ember.isEmpty(nodeVersion)) {
        Ember.set(this, 'nodePool.orchestratorVersion', clusterVersion); // nodeVersion = clusterVersion;

        return false;
      }

      const initalClusterMinorVersion = parseInt(_semver.default.minor(_semver.default.coerce(clusterVersion)), 10);
      const initalNodeMinorVersion = parseInt(_semver.default.minor(_semver.default.coerce(nodeVersion)), 10);
      const diff = initalClusterMinorVersion - initalNodeMinorVersion;

      if (mode === 'edit') {
        // we must upgrade the cluster first
        if (originalClusterVersion !== clusterVersion) {
          Ember.set(this, 'showNodeUpgradePreventionReason', true);
          return false;
        }
      }

      if (diff === 0 && Ember.get(this, 'showNodeUpgradePreventionReason')) {
        Ember.set(this, 'showNodeUpgradePreventionReason', false);
      } // return diff === 1;


      if (Ember.isEmpty(clusterVersion) || Ember.isEmpty(nodeVersion)) {
        return false;
      }

      const nodeIsLess = _semver.default.lt(nodeVersion, clusterVersion, {
        includePrerelease: true
      });

      if (nodeIsLess) {
        return true;
      }

      return false;
    }),
    machineSizes: Ember.computed('vmSizes.[]', function () {
      return ((this === null || this === void 0 ? void 0 : this.vmSizes) || []).map(vm => {
        return {
          value: vm
        };
      });
    }),
    isNewNodePool: Ember.computed('nodePool.isNew', function () {
      var _this$nodePool7;

      return (this === null || this === void 0 ? void 0 : (_this$nodePool7 = this.nodePool) === null || _this$nodePool7 === void 0 ? void 0 : _this$nodePool7.isNew) ?? false;
    }),
    displayZones: Ember.computed('nodePool.availabilityZones.[]', function () {
      var _this$nodePool8;

      return ((this === null || this === void 0 ? void 0 : (_this$nodePool8 = this.nodePool) === null || _this$nodePool8 === void 0 ? void 0 : _this$nodePool8.availabilityZones) || []).slice().join(', ');
    }),
    // First pool is always the primary pool
    // Node pools is an array and first one can not be removed
    primaryPool: Ember.computed('cluster.aksConfig.nodePools.[]', 'cluster.aksConfig', 'nodePool', function () {
      var _this$cluster;

      const {
        nodePools
      } = (_this$cluster = this.cluster) === null || _this$cluster === void 0 ? void 0 : _this$cluster.aksConfig;
      const index = nodePools.findIndex(pool => pool === this.nodePool); // First pool is always the primary pool

      return index === 0;
    }),
    shouldDisableNodeCount: Ember.computed('nodePool.enableAutoScaling', 'isNewNodePool', function () {
      const {
        nodePool
      } = this;

      if (nodePool !== null && nodePool !== void 0 && nodePool.enableAutoScaling) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});