define("shared/components/input-array-as-string/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q37xpxvs",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"value\",\"class\",\"type\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"type\",\"inputClass\",\"asString\",\"input\"]}",
    "moduleName": "shared/components/input-array-as-string/template.hbs"
  });

  _exports.default = _default;
});