define("shared/components/sortable-table/component", ["exports", "shared/mixins/sortable-base", "shared/mixins/sticky-table-header", "shared/components/sortable-table/template", "ember-cli-pagination/computed/paged-array", "shared/utils/platform", "ui/utils/search-text"], function (_exports, _sortableBase, _stickyTableHeader, _template, _pagedArray, _platform, _searchText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function toggleInput(node, on) {
    let id = Ember.get(node, 'id');

    if (id) {
      let input = $(`input[nodeid="${id}"]`); // eslint-disable-line

      if (input && input.length && !input[0].disabled) {
        // can't reuse the input ref here because the table has rerenderd and the ref is no longer good
        $(`input[nodeid="${id}"]`).prop('checked', on); // eslint-disable-line

        let tr = $(`input[nodeid="${id}"]`).closest('tr'); // eslint-disable-line

        let first = true;

        while (tr && (first || tr.hasClass('sub-row'))) {
          tr.toggleClass('row-selected', on);
          tr = tr.next();
          first = false;
        }
      }
    }
  }

  var _default = Ember.Component.extend(_sortableBase.default, _stickyTableHeader.default, {
    prefs: Ember.inject.service(),
    intl: Ember.inject.service(),
    bulkActionHandler: Ember.inject.service(),
    layout: _template.default,
    body: null,
    groupByKey: null,
    groupByRef: null,
    preSorts: null,
    sortBy: null,
    descending: false,
    headers: null,
    extraSearchFields: null,
    extraSearchSubFields: null,
    prefix: false,
    suffix: false,
    bulkActions: true,
    rowActions: true,
    search: true,
    searchToWormhole: null,
    paging: true,
    subRows: false,
    checkWidth: 40,
    actionsWidth: 40,
    availableActions: null,
    selectedNodes: null,
    prevNode: null,
    searchText: null,
    isVisible: true,
    page: 1,
    pagingLabel: 'pagination.generic',
    selectionChanged: null,
    showHeader: Ember.computed.or('bulkActions', 'searchInPlace'),
    // -----
    sortableContent: Ember.computed.alias('body'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedNodes', []);

      if (Ember.get(this, 'bulkActions')) {
        this.actionsChanged();
      }

      if (Ember.get(this, 'bulkActions')) {
        Ember.run.schedule('afterRender', () => {
          let table = $(this.element).find('> TABLE'); // eslint-disable-line

          let self = this; // need this context in click function and can't use arrow func there

          table.on('click', '> TBODY > TR', e => {
            self.rowClick(e);
          });
          table.on('mousedown', '> TBODY > TR', e => {
            if ((0, _platform.isRange)(e) || e.target.tagName === 'INPUT') {
              e.preventDefault();
            }
          });
        });
      }

      let watchKey = 'pagedContent.[]';

      if (Ember.get(this, 'groupByKey')) {
        watchKey = `pagedContent.@each.${Ember.get(this, 'groupByKey').replace(/\..*/g, '')}`;
      }

      Ember.defineProperty(this, 'groupedContent', Ember.computed(watchKey, 'groupByKey', 'groupByRef', 'pagedContent', 'selectedNodes', () => {
        let ary = [];
        let map = {};
        let groupKey = Ember.get(this, 'groupByKey');
        let refKey = Ember.get(this, 'groupByRef') || '';
        Ember.get(this, 'pagedContent').forEach(obj => {
          let group = Ember.get(obj, groupKey) || '';
          let ref = Ember.get(obj, refKey) || {
            displayName: group
          };
          let entry = map[group];

          if (entry) {
            entry.items.push(obj);
          } else {
            entry = {
              group,
              ref,
              items: [obj]
            };
            map[group] = entry;
            ary.push(entry);
          }

          if (Ember.get(this, 'selectedNodes').includes(obj)) {
            Ember.run.next(this, () => {
              toggleInput(obj, true);
            });
          }
        });
        return ary;
      }));
    },

    didReceiveAttrs() {
      if (Ember.get(this, 'isVisible')) {
        this.triggerResize();
      }
    },

    actions: {
      clearSearch() {
        Ember.set(this, 'searchText', '');
      },

      executeBulkAction(name, e) {
        e.preventDefault();
        let handler = Ember.get(this, 'bulkActionHandler');
        let nodes = Ember.get(this, 'selectedNodes');

        if ((0, _platform.isAlternate)(e)) {
          var available = Ember.get(this, 'availableActions');
          var action = available.findBy('action', name);
          let alt = Ember.get(action, 'altAction');

          if (alt) {
            name = alt;
          }
        }

        if (typeof handler[name] === 'function') {
          Ember.get(this, 'bulkActionHandler')[name](nodes);
        } else {
          nodes.forEach(node => {
            node.send(name);
          });
        }
      },

      executeAction(action) {
        var node = Ember.get(this, 'selectedNodes')[0];
        node.send(action);
      }

    },
    selection: Ember.observer('selectedNodes.[]', function () {
      const callback = Ember.get(this, 'selectionChanged');

      if (typeof callback === 'function') {
        callback(Ember.get(this, 'selectedNodes'));
      }
    }),
    // Pick a new sort if the current column disappears.
    headersChanged: Ember.observer('headers.@each.name', function () {
      let sortBy = Ember.get(this, 'sortBy');
      let headers = Ember.get(this, 'headers') || [];

      if (headers && headers.get('length')) {
        let cur = headers.findBy('name', sortBy);

        if (!cur) {
          Ember.run.next(this, function () {
            this.send('changeSort', headers.get('firstObject.name'));
          });
        }
      }
    }),
    pagedContentChanged: Ember.observer('pagedContent.[]', function () {
      this.cleanupOrphans();
    }),
    pageCountChanged: Ember.observer('indexFrom', 'filtered.length', function () {
      // Go to the last page if we end up past the last page
      let from = Ember.get(this, 'indexFrom');
      let last = Ember.get(this, 'filtered.length');
      var perPage = Ember.get(this, 'perPage');

      if (Ember.get(this, 'page') > 1 && from > last) {
        let page = Math.ceil(last / perPage);
        Ember.set(this, 'page', page);
      }
    }),
    sortKeyChanged: Ember.observer('sortBy', function () {
      Ember.set(this, 'page', 1);
    }),
    actionsChanged: Ember.observer('selectedNodes.@each._availableActions', 'pagedContent.@each._availableActions', function () {
      if (!Ember.get(this, 'bulkActions')) {
        return;
      }

      let nodes = Ember.get(this, 'selectedNodes');
      let disableAll = false;

      if (!nodes.length) {
        disableAll = true;
        let firstNode = Ember.get(this, 'pagedContent.firstObject');

        if (firstNode) {
          nodes = [firstNode];
        }
      }

      const map = {};
      Ember.get(this, 'pagedContent').forEach(node => {
        Ember.get(node, '_availableActions').forEach(act => {
          if (!act.bulkable) {
            return;
          }

          let obj = map[act.action];

          if (!obj) {
            obj = $().extend(true, {}, act); // eslint-disable-line

            map[act.action] = obj;
          }

          if (act.enabled !== false) {
            obj.anyEnabled = true;
          }
        });
      });
      nodes.forEach(node => {
        Ember.get(node, '_availableActions').forEach(act => {
          if (!act.bulkable) {
            return;
          }

          let obj = map[act.action];

          if (!obj) {
            obj = $().extend(true, {}, act); // eslint-disable-line

            map[act.action] = obj;
          }

          obj.available = (obj.available || 0) + (act.enabled === false ? 0 : 1);
          obj.total = (obj.total || 0) + 1;
        });
      });
      let out = Object.values(map).filterBy('anyEnabled', true);

      if (disableAll) {
        out.forEach(x => {
          Ember.set(x, 'enabled', false);
        });
      } else {
        out.forEach(x => {
          if (x.available < x.total) {
            Ember.set(x, 'enabled', false);
          } else {
            Ember.set(x, 'enabled', true);
          }
        });
      }

      Ember.set(this, 'availableActions', out);
    }),
    searchInPlace: Ember.computed('search', 'searchToWormhole', function () {
      return Ember.get(this, 'search') && !Ember.get(this, 'searchToWormhole');
    }),
    perPage: Ember.computed('paging', 'prefs.tablePerPage', function () {
      if (Ember.get(this, 'paging')) {
        return Ember.get(this, 'prefs.tablePerPage');
      } else {
        return 100000;
      }
    }),
    // hide bulckActions if content is empty.
    internalBulkActions: Ember.computed('bulkActions', 'sortableContent.[]', function () {
      let bulkActions = Ember.get(this, 'bulkActions');

      if (bulkActions && Ember.get(this, 'sortableContent')) {
        let sortableContent = Ember.get(this, 'sortableContent');
        return !!sortableContent.get('length');
      } else {
        return false;
      }
    }),
    // Flow: body [-> sortableContent] -> arranged -> filtered -> pagedContent [-> groupedContent]
    pagedContent: (0, _pagedArray.default)('filtered.[]', {
      page: Ember.computed.alias('parent.page'),
      perPage: Ember.computed.alias('parent.perPage')
    }),
    // For data-title properties on <td>s
    dt: Ember.computed('headers.@each.{name,label,translationKey}', 'intl.locale', function () {
      let intl = Ember.get(this, 'intl');
      let out = {
        select: `${intl.t('generic.select')}: `,
        actions: `${intl.t('generic.actions')}: `
      };
      Ember.get(this, 'headers').forEach(header => {
        let name = Ember.get(header, 'name');
        let dtKey = Ember.get(header, 'dtTranslationKey');
        let key = Ember.get(header, 'translationKey');

        if (dtKey) {
          out[name] = `${intl.t(dtKey)}: `;
        } else if (key) {
          out[name] = `${intl.t(key)}: `;
        } else {
          out[name] = `${Ember.get(header, 'label') || name}: `;
        }
      });
      return out;
    }),
    // Table content
    fullColspan: Ember.computed('headers.length', 'bulkActions', 'rowActions', function () {
      return (Ember.get(this, 'headers.length') || 0) + (Ember.get(this, 'bulkActions') ? 1 : 0) + (Ember.get(this, 'rowActions') ? 1 : 0);
    }),
    // -----
    searchFields: Ember.computed('headers.@each.{searchField,name}', 'extraSearchFields.[]', function () {
      let out = headersToSearchField(Ember.get(this, 'headers'));
      return out.addObjects(Ember.get(this, 'extraSearchFields') || []);
    }),
    subFields: Ember.computed('subHeaders.@each.{searchField,name}', 'extraSearchSubFields.[]', function () {
      let out = headersToSearchField(Ember.get(this, 'subHeaders'));
      return out.addObjects(Ember.get(this, 'extraSearchSubFields') || []);
    }),
    showPaging: Ember.computed('filtered.[]', 'pagedContent.content.[]', function () {
      const filtered = Ember.get(this, 'filtered');
      const pagedContent = Ember.get(this, 'pagedContent');

      if (Ember.get(filtered, 'length') > Ember.get(pagedContent, 'length')) {
        return true;
      } else {
        return false;
      }
    }),
    filtered: Ember.computed('arranged.[]', 'searchFields', 'searchText', 'subFields', 'subSearchField', function () {
      const {
        matches,
        subMatches
      } = (0, _searchText.filter)(Ember.get(this, 'arranged').slice(), Ember.get(this, 'searchText'), Ember.get(this, 'searchFields'), Ember.get(this, 'subFields'), Ember.get(this, 'subSearchField'));
      Ember.set(this, 'subMatches', subMatches);
      return matches;
    }),
    indexFrom: Ember.computed('page', 'perPage', function () {
      var current = Ember.get(this, 'page');
      var perPage = Ember.get(this, 'perPage');
      return Math.max(0, 1 + perPage * (current - 1));
    }),
    indexTo: Ember.computed('indexFrom', 'perPage', 'filtered.length', function () {
      return Math.min(Ember.get(this, 'filtered.length'), Ember.get(this, 'indexFrom') + Ember.get(this, 'perPage') - 1);
    }),
    pageCountContent: Ember.computed('filtered.length', 'indexFrom', 'indexTo', 'pagedContent.totalPages', function () {
      let from = Ember.get(this, 'indexFrom') || 0;
      let to = Ember.get(this, 'indexTo') || 0;
      let count = Ember.get(this, 'filtered.length') || 0;
      let pages = Ember.get(this, 'pagedContent.totalPages') || 0;
      let out = '';

      if (pages <= 1) {
        out = `${count} Item${count === 1 ? '' : 's'}`;
      } else {
        out = `${from} - ${to} of ${count}`;
      }

      return out;
    }),
    isAll: Ember.computed('selectedNodes.length', 'pagedContent.length', {
      get() {
        return Ember.get(this, 'selectedNodes.length') === Ember.get(this, 'pagedContent.length');
      },

      set(key, value) {
        var content = Ember.get(this, 'pagedContent').filterBy('canBulkRemove');

        if (value) {
          this.toggleMulti(content, []);
        } else {
          this.toggleMulti([], content);
        }

        return Ember.get(this, 'selectedNodes.length') === Ember.get(this, 'pagedContent.length');
      }

    }),

    cleanupOrphans() {
      // Remove selected items not in the current content
      let content = Ember.get(this, 'pagedContent');
      let nodesToAdd = [];
      let nodesToRemove = [];
      Ember.get(this, 'selectedNodes').forEach(node => {
        if (content.includes(node)) {
          nodesToAdd.push(node);
        } else {
          nodesToRemove.push(node);
        }
      });
      this.toggleMulti(nodesToAdd, nodesToRemove);
    },

    // ------
    // Clicking
    // ------
    rowClick(e) {
      let tagName = e.target.tagName;
      let tgt = $(e.target); // eslint-disable-line

      if (tagName === 'A' || tagName === 'BUTTON' || tgt.parents('.btn').length || typeof tgt.data('ember-action') !== 'undefined' || tgt.hasClass('copy-btn')) {
        return;
      }

      let content = Ember.get(this, 'pagedContent');
      let selection = Ember.get(this, 'selectedNodes');
      let isCheckbox = tagName === 'INPUT' || tgt.hasClass('row-check');
      let tgtRow = $(e.currentTarget); // eslint-disable-line

      if (tgtRow.hasClass('separator-row') || tgt.hasClass('select-all-check')) {
        return;
      }

      while (tgtRow && tgtRow.length && !tgtRow.hasClass('main-row')) {
        tgtRow = tgtRow.prev();
      }

      if (!tgtRow || !tgtRow.length) {
        return;
      }

      let nodeId = tgtRow.find('input[type="checkbox"]').attr('nodeid');
      let check = tgtRow.find('input[type="checkbox"]')[0];

      if (!nodeId || !check || check.disabled) {
        return;
      }

      let node = content.findBy('id', nodeId);

      if (!node) {
        return;
      }

      let isSelected = selection.includes(node);
      let prevNode = Ember.get(this, 'prevNode'); // PrevNode is only valid if it's in the current content

      if (!prevNode || !content.includes(prevNode)) {
        prevNode = node;
      }

      if ((0, _platform.isMore)(e)) {
        this.toggleSingle(node);
      } else if ((0, _platform.isRange)(e)) {
        let toToggle = this.nodesBetween(prevNode, node);

        if (isSelected) {
          this.toggleMulti([], toToggle);
        } else {
          this.toggleMulti(toToggle, []);
        }
      } else if (isCheckbox) {
        this.toggleSingle(node);
      } else {
        this.toggleMulti([node], content);
      }

      Ember.set(this, 'prevNode', node);
    },

    nodesBetween(a, b) {
      let toToggle = [];
      let key = Ember.get(this, 'groupByKey');

      if (key) {
        // Grouped has 2 levels to look through
        let grouped = Ember.get(this, 'groupedContent');
        let from = this.groupIdx(a);
        let to = this.groupIdx(b);

        if (!from || !to) {
          return [];
        } // From has to come before To


        if (from.group > to.group || from.group === to.group && from.item > to.item) {
          [from, to] = [to, from];
        }

        for (let i = from.group; i <= to.group; i++) {
          let items = grouped.objectAt(i).items;
          let j = from.group === i ? from.item : 0;

          while (items[j] && (i < to.group || j <= to.item)) {
            toToggle.push(items[j]);
            j++;
          }
        }
      } else {
        // Ungrouped is much simpler
        let content = Ember.get(this, 'pagedContent');
        let from = content.indexOf(a);
        let to = content.indexOf(b);
        [from, to] = [Math.min(from, to), Math.max(from, to)];
        toToggle = content.slice(from, to + 1);
      }

      return toToggle;
    },

    groupIdx(node) {
      let grouped = Ember.get(this, 'groupedContent');

      for (let i = 0; i < grouped.get('length'); i++) {
        let items = grouped.objectAt(i).items;

        for (let j = 0; j < items.get('length'); j++) {
          if (items.objectAt(j) === node) {
            return {
              i,
              item: j
            };
          }
        }
      }

      return null;
    },

    toggleSingle(node) {
      let selectedNodes = Ember.get(this, 'selectedNodes');

      if (selectedNodes.includes(node)) {
        this.toggleMulti([], [node]);
      } else {
        this.toggleMulti([node], []);
      }
    },

    toggleMulti(nodesToAdd, nodesToRemove) {
      let selectedNodes = Ember.get(this, 'selectedNodes');

      if (nodesToRemove.length) {
        // removeObjects doesn't use ArrayProxy-safe looping
        if (typeof nodesToRemove.toArray === 'function') {
          nodesToRemove = nodesToRemove.toArray();
        }

        selectedNodes.removeObjects(nodesToRemove);
        toggle(nodesToRemove, false);
      }

      if (nodesToAdd.length) {
        selectedNodes.addObjects(nodesToAdd);
        toggle(nodesToAdd, true);
      }

      function toggle(nodes, on) {
        Ember.run.next(() => {
          nodes.forEach(node => {
            toggleInput(node, on);
          });
        });
      }
    }

  });

  _exports.default = _default;

  function headersToSearchField(headers) {
    let out = [];
    (headers || []).forEach(header => {
      let field = Ember.get(header, 'searchField');

      if (field) {
        if (typeof field === 'string') {
          out.addObject(field);
        } else if (Ember.isArray(field)) {
          out.addObjects(field);
        }
      } else if (field === false) {// Don't add the name
      } else {
        out.addObject(Ember.get(header, 'name'));
      }
    });
    return out.filter(x => !!x);
  }
});