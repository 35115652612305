define("shared/components/form-affinity-k8s/component", ["exports", "shared/components/form-affinity-k8s/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    value: null,
    editing: false,
    podAffinity: Ember.computed('value.podAffinity.{preferredDuringSchedulingIgnoredDuringExecution,requiredDuringSchedulingIgnoredDuringExecution}', {
      get() {
        var _this$value;

        if (!((_this$value = this.value) !== null && _this$value !== void 0 && _this$value.podAffinity)) {
          Ember.set(this.value, 'podAffinity', {});
        }

        this.initPreferredRequired(this.value.podAffinity);
        return Ember.get(this.value, 'podAffinity');
      },

      set(key, val) {
        const withoutId = {
          preferredDuringSchedulingIgnoredDuringExecution: this.removeIds(val.preferredDuringSchedulingIgnoredDuringExecution),
          requiredDuringSchedulingIgnoredDuringExecution: this.removeIds(val.requiredDuringSchedulingIgnoredDuringExecution)
        };
        Ember.set(this.value, 'podAffinity', withoutId);
        this.notifyPropertyChange('value');
        return val;
      }

    }),
    podAntiAffinity: Ember.computed('value.podAntiAffinity.{preferredDuringSchedulingIgnoredDuringExecution,requiredDuringSchedulingIgnoredDuringExecution}', {
      get() {
        var _this$value2;

        if (!((_this$value2 = this.value) !== null && _this$value2 !== void 0 && _this$value2.podAntiAffinity)) {
          Ember.set(this.value, 'podAntiAffinity', {});
        }

        this.initPreferredRequired(this.value.podAntiAffinity);
        return Ember.get(this.value, 'podAntiAffinity');
      },

      set(key, val) {
        const withoutId = {
          preferredDuringSchedulingIgnoredDuringExecution: this.removeIds(val.preferredDuringSchedulingIgnoredDuringExecution),
          requiredDuringSchedulingIgnoredDuringExecution: this.removeIds(val.requiredDuringSchedulingIgnoredDuringExecution)
        };
        Ember.set(this.value, 'podAntiAffinity', withoutId);
        this.notifyPropertyChange('value');
        return val;
      }

    }),
    nodeAffinity: Ember.computed('value.nodeAffinity.preferredDuringSchedulingIgnoredDuringExecution', 'value.nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms', {
      get() {
        var _this$value3;

        if (!((_this$value3 = this.value) !== null && _this$value3 !== void 0 && _this$value3.nodeAffinity)) {
          Ember.set(this.value, 'nodeAffinity', {});
        }

        this.initPreferredRequired(Ember.get(this.value, 'nodeAffinity'), true);

        if (!this.value.nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms) {
          Ember.set(this.value, 'nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms', []);
        }

        return Ember.get(this.value, 'nodeAffinity');
      },

      set(key, val) {
        const withoutId = {
          preferredDuringSchedulingIgnoredDuringExecution: this.removeIds(val.preferredDuringSchedulingIgnoredDuringExecution),
          requiredDuringSchedulingIgnoredDuringExecution: {
            nodeSelectorTerms: this.removeIds(val.requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms)
          }
        };
        Ember.set(this.value, 'nodeAffinity', withoutId);
        this.notifyPropertyChange('value');
        return val;
      }

    }),
    initPreferredRequired: (val, isNode) => {
      if (!val.preferredDuringSchedulingIgnoredDuringExecution) {
        Ember.set(val, 'preferredDuringSchedulingIgnoredDuringExecution', []);
      }

      if (!val.requiredDuringSchedulingIgnoredDuringExecution) {
        if (isNode) {
          Ember.set(val, 'requiredDuringSchedulingIgnoredDuringExecution', {
            nodeSelectorTerms: []
          });
        } else {
          Ember.set(val, 'requiredDuringSchedulingIgnoredDuringExecution', []);
        }
      }
    },
    removeIds: (terms = []) => {
      return terms.map(term => {
        delete term._id;
        return term;
      });
    }
  });

  _exports.default = _default;
});