define("shared/components/input-array-as-string/component", ["exports", "shared/components/input-array-as-string/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    attributeBindings: ['data-testid'],
    value: null,
    // pass in a class to style the input component directly
    inputClass: '',
    type: 'text',

    init() {
      this._super(...arguments);
    },

    asString: Ember.computed('value', {
      get() {
        const value = this.get('value') || '';
        return typeof value === 'string' ? value : value.join(', ');
      },

      set(key, val = '') {
        const out = val.split(/,|, /g).reduce((all, s) => {
          const trimmed = s.trim();

          if (trimmed.length) {
            if (this.type === 'number') {
              const asInt = parseInt(trimmed, 10);
              all.push(asInt);
            } else {
              all.push(trimmed);
            }
          }

          return all;
        }, []);
        this.set('value', out);
        return val;
      }

    })
  });

  _exports.default = _default;
});