define("shared/user-theme/service", ["exports", "jquery", "shared/utils/constants", "shared/utils/util"], function (_exports, _jquery, _constants, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    prefs: Ember.inject.service(),
    session: Ember.inject.service(),
    language: Ember.inject.service('user-language'),
    currentTheme: null,
    updateTimer: null,
    app: Ember.inject.service(),
    cookies: Ember.inject.service(),

    setupTheme() {
      const cachedTheme = this.cookies.get(_constants.default.COOKIE.THEME);
      const personalTheme = cachedTheme ? `ui-${cachedTheme}` : undefined;
      var defaultTheme = this.get('session').get(_constants.default.PREFS.THEME) || personalTheme;
      var userTheme = this.get(`prefs.${_constants.default.PREFS.THEME}`);

      if (userTheme) {
        this.setTheme(userTheme, false);
      } else {
        // no user pref'd theme
        if ((0, _util.isEmbedded)() && Ember.isEmpty(defaultTheme)) {
          // on an upgrade if the user theme pref was never set and we're in the dashboard which defaults to ui-auto
          defaultTheme = 'ui-auto';
        }

        this.setTheme(defaultTheme, true);
      }
    },

    setTheme(newTheme, save = true) {
      if (save) {
        this.set(`prefs.${_constants.default.PREFS.THEME}`, newTheme);
      }

      if (this.get('updateTimer')) {
        Ember.run.cancel(this.get('updateTimer'));
      }

      if (newTheme === 'ui-auto') {
        this.setAutoUpdate();
      } else {
        this.set('currentTheme', newTheme);
        this.writeStyleNode();
      }
    },

    getTheme() {
      return this.get(`prefs.${_constants.default.PREFS.THEME}`);
    },

    setAutoUpdate() {
      var self = this;
      const watchDark = window.matchMedia('(prefers-color-scheme: dark)');
      const watchLight = window.matchMedia('(prefers-color-scheme: light)');
      const watchNone = window.matchMedia('(prefers-color-scheme: no-preference)');

      var nextHalfHour = _constants.default.THEME.AUTO_UPDATE_TIMER - Math.round(new Date().getTime()) % _constants.default.THEME.AUTO_UPDATE_TIMER;

      if (!this.updateTimer) {
        if (watchDark.matches) {
          changed('ui-dark');
        } else if (watchLight.matches) {
          changed('ui-light');
        } else {
          changed(fromClock());
        }

        watchDark.addListener(e => {
          if (e.matches) {
            changed('ui-dark');
          }
        });
        watchLight.addListener(e => {
          if (e.matches) {
            changed('ui-light');
          }
        });
        watchNone.addListener(e => {
          if (e.matches) {
            changed(fromClock());
          }
        });
      }

      this.set('updateTimer', Ember.run.later(() => {
        return this.setAutoUpdate();
      }, nextHalfHour));

      function fromClock() {
        const hour = new Date().getHours();

        if (hour < _constants.default.THEME.START_HOUR || hour >= _constants.default.THEME.END_HOUR) {
          return 'ui-dark';
        }

        return 'ui-light';
      }

      function changed(newTheme) {
        console.log('Theme change', newTheme);
        self.set('currentTheme', newTheme);
        self.writeStyleNode();
      }
    },

    writeStyleNode() {
      var application = this.get('app');
      var $body = (0, _jquery.default)('BODY');
      let theme = this.get('currentTheme');
      let lang = this.get(`session.${_constants.default.SESSION.LANGUAGE}`);
      var direction = '';
      let assets = application.get('baseAssets');
      let version = application.get('version');

      if (!theme || !lang) {
        return;
      }

      $body.attr('class').split(/\s+/).forEach(cls => {
        if (cls.indexOf('theme-') === 0) {
          $body.removeClass(cls);
        }
      });
      $body.addClass(`theme-${theme}`);

      if (this.get('language').isRtl(lang)) {
        direction = '.rtl';
      }

      updateHref('#theme', `${assets}assets/${theme}${direction}.css?${version}`);
      updateHref('#vendor', `${assets}assets/vendor${direction}.css?${version}`);

      function updateHref(node, neu) {
        let elem = (0, _jquery.default)(node);
        let cur = elem.attr('href');

        if (cur !== neu) {
          elem.attr('href', neu);
        }
      }
    }

  });

  _exports.default = _default;
});