define("shared/components/modal-add-custom-roles/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-add-custom-roles/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['small-modal'],
    mode: null,
    errors: null,
    type: Ember.computed.alias('modalOpts.type'),
    current: Ember.computed.alias('modalOpts.current'),
    isCurrentCustom: Ember.computed.alias('modalOpts.isCustom'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        mode: 'custom',
        cTyped: Ember.get(this, 'type').capitalize()
      });
    },

    actions: {
      save(cb) {
        let customroles = Ember.get(this, 'filteredRoles').filter(c => Ember.get(c, 'active')).map(r => Ember.get(r, 'role.id'));

        if (Ember.get(this, 'mode') !== 'custom') {
          customroles.push(Ember.get(this, 'mode')); // from custom to non
        }

        if (customroles.length > 0) {
          Ember.set(this, 'errors', null);
          Ember.get(this, 'modalOpts.done')(customroles);
          this.get('modalService').toggleModal();
        } else {
          Ember.set(this, 'errors', ['You must choose a role for this member before closing the modal.']);
          return cb(false);
        }
      },

      completed() {
        this.get('modalService').toggleModal();
      },

      goBack() {
        Ember.get(this, 'modalOpts.modalCanceled')();
        this.get('modalService').toggleModal();
      },

      toggle(e) {
        const $target = $(e.target); // eslint-disable-line

        const $row = $target.closest('.input-group');
        const check = $('input[type=checkbox]', $row)[0]; // eslint-disable-line

        if (check && e.target !== check && e.target.tagName !== 'LABEL') {
          check.click();
        }
      }

    },
    filteredRoles: Ember.computed('modalOpts.current.length', 'modalOpts.roles', function () {
      return Ember.get(this, 'modalOpts.roles').filterBy('isCustom').map(role => {
        let binding = null;

        if (Ember.get(this, 'modalOpts.current.length')) {
          binding = Ember.get(this, 'modalOpts.current').includes(Ember.get(role, 'id')) ? role : null;
        }

        return {
          role,
          active: !!binding,
          existing: binding
        };
      });
    }),
    roleTemplateId: Ember.computed('mode', 'type', {
      get(key) {
        if (Ember.get(this, 'mode') === 'custom') {
          return 'custom';
        } else {
          return `${Ember.get(this, 'type')}-${key}`;
        }
      },

      set(key, value) {
        if (value === 'custom') {
          return Ember.set(this, 'mode', 'custom');
        } else {
          Ember.get(this, 'filteredRoles').forEach(c => Ember.set(c, 'active', false));
          return Ember.set(this, 'mode', `${Ember.get(this, 'type')}-${value}`);
        }
      }

    })
  });

  _exports.default = _default;
});