define("global-admin/clusters/new/launch/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),
    clusterTemplateService: Ember.inject.service('clusterTemplates'),
    _cachedClusterDetails: null,

    beforeModel(transition) {
      let {
        me: {
          hasAdmin: globalAdmin = false
        }
      } = this.access;

      if (!globalAdmin) {
        const {
          clusterTemplates
        } = this.modelFor('clusters.new');
        let {
          clusterTemplateEnforcement = false
        } = this.settings; // setting is string value

        if (clusterTemplateEnforcement === 'true') {
          clusterTemplateEnforcement = true;
        } else {
          clusterTemplateEnforcement = false;
        }

        if (clusterTemplateEnforcement) {
          if (clusterTemplates.length === 1 && Ember.isEmpty(transition.to.params.clusterTemplateRevision)) {
            return this.replaceWith(this.routeName, transition.to.params.provider, {
              queryParams: {
                clusterTemplateRevision: clusterTemplates.firstObject.defaultRevisionId
              }
            });
          }
        }
      }

      return;
    },

    model(params) {
      const gs = this.globalStore;
      const {
        kontainerDrivers,
        nodeDrivers,
        clusterTemplates,
        clusterTemplateRevisions
      } = this.modelFor('clusters.new');
      const {
        provider,
        clusterTemplateRevision
      } = params;
      let cluster = gs.createRecord({
        type: 'cluster',
        name: ''
      });
      let ctr = null;
      let ct = null;
      let ctId = null;
      ctr = clusterTemplateRevisions.findBy('id', clusterTemplateRevision);

      if (clusterTemplateRevision && ctr) {
        ctId = Ember.get(ctr, 'clusterTemplateId');
        ct = clusterTemplates.findBy('id', ctId);
        Ember.setProperties(cluster, {
          clusterTemplateRevisionId: clusterTemplateRevision,
          clusterTemplateId: Ember.get(ct, 'id')
        });
        this.clusterTemplateService.cloneAndPopulateClusterConfig(cluster, ctr);
      } else {
        if (cluster.clusterTemplateId && cluster.clusterTemplateRevisionId && !clusterTemplateRevision) {
          // user deselected RKE Template
          Ember.setProperties(cluster, {
            clusterTemplateId: null,
            clusterTemplateRevisionId: null
          });
        }
      }

      if (this._cachedClusterDetails) {
        Ember.set(cluster, 'name', this._cachedClusterDetails.name);
        Ember.set(this, '_cachedClusterDetails', null);
      }

      return Ember.RSVP.hash({
        cluster,
        kontainerDrivers,
        nodeDrivers,
        provider,
        clusterTemplateRevision: ctr,
        roleTemplates: Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(),
        me: Ember.get(this, 'access.principal'),
        cloudCredentials: gs.findAll('cloudcredential'),
        clusterRoleTemplateBinding: gs.findAll('clusterRoleTemplateBinding'),
        nodeTemplates: gs.findAll('nodeTemplate'),
        psps: gs.findAll('podSecurityPolicyTemplate'),
        psacs: gs.findAll('podSecurityAdmissionConfigurationTemplate'),
        users: gs.findAll('user'),
        clusterTemplates,
        clusterTemplateRevisions
      });
    },

    resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.setProperties({
          clusterTemplateRevision: null,
          importProvider: null,
          register: false
        });
      }
    },

    actions: {
      willTransition(transition) {
        if (transition.queryParamsOnly) {
          let name = this.controller.model.cluster.name ? this.controller.model.cluster.name : null;
          Ember.set(this, '_cachedClusterDetails', {
            name
          });
        } else {
          if (this._cachedClusterDetails) {
            Ember.set(this, '_cachedClusterDetails', null);
          }
        }
      }

    },
    queryParams: {
      clusterTemplateRevision: {
        refreshModel: true
      },
      importProvider: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});