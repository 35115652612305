define("nodes/components/driver-azure/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-azure/template", "ui/utils/azure-choices", "shared/utils/util"], function (_exports, _nodeDriver, _template, _azureChoices, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DRIVER = 'azure';
  const CONFIG = 'azureConfig';
  const IPCHOICES = [{
    'name': 'Static',
    'value': 'staticPublicIp=true,noPublicIp=false'
  }, {
    'name': 'Dynamic',
    'value': 'staticPublicIp=false,noPublicIp=false'
  }, {
    'name': 'None',
    'value': 'staticPublicIp=true,noPublicIp=true'
  }];
  const MANAGED = 'managed';
  const UNMANAGED = 'unmanaged';
  const DISK_CHOICES = [{
    label: 'nodeDriver.azure.managedDisks.unmanaged',
    value: UNMANAGED
  }, {
    label: 'nodeDriver.azure.managedDisks.managed',
    value: MANAGED
  }];

  var _default = Ember.Component.extend(_nodeDriver.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    environments: _azureChoices.environments,
    driverName: DRIVER,
    publicIpChoices: IPCHOICES,
    diskChoices: DISK_CHOICES,
    managedDisks: UNMANAGED,
    model: null,
    openPorts: null,
    publicIpChoice: null,
    tags: null,
    step: 1,
    useAvailabilitySet: true,
    azureCredentialSecret: null,
    regions: [],
    regionsLoading: true,
    vmSizes: [],
    vmSizesLoading: true,
    cloudCredentialId: '',
    config: Ember.computed.alias(`model.${CONFIG}`),
    storageTypeChoices: _azureChoices.storageTypes.sortBy('name'),
    showVmAvailabilityZoneWarning: Ember.computed.gt('vmAvailabilityZoneWarning.length', 0),
    showVmSizeAvailabilityWarning: Ember.computed.gt('vmSizeAvailabilityWarning.length', 0),
    showVmSizeAcceleratedNetworkingWarning: Ember.computed.gt('vmSizeAcceleratedNetworkingWarning.length', 0),

    init() {
      this._super(...arguments);

      const tagsString = Ember.get(this, 'config.tags');

      if (tagsString) {
        const array = tagsString.split(',');
        const tags = {};

        for (let i = 0; i < array.length - 1; i = i + 2) {
          tags[array[i]] = array[i + 1];
        }

        Ember.set(this, 'tags', tags);
      }

      const availabilityZone = Ember.get(this, 'config.availabilityZone');

      if (availabilityZone) {
        Ember.set(this, 'useAvailabilitySet', false);
      }

      Ember.run.scheduleOnce('afterRender', this, this.setupComponent);
    },

    actions: {
      finishAndSelectCloudCredential(cred) {
        if (cred) {
          Ember.set(this, 'primaryResource.cloudCredentialId', Ember.get(cred, 'id'));
        }
      },

      initAzureData(cb) {
        const cloudCredentialId = Ember.get(this, 'primaryResource.cloudCredentialId');
        Ember.set(this, 'cloudCredentialId', cloudCredentialId);
        this.fetchVmSizes();
        this.fetchRegions();
        Ember.setProperties(this, {
          'step': 2
        });

        if (cb && typeof cb === 'function') {
          cb();
        }
      }

    },
    diskTypeChanged: Ember.observer('managedDisks', function () {
      Ember.set(this, 'config.managedDisks', Ember.get(this, 'managedDisks') === MANAGED);
    }),
    locationObserver: Ember.observer('config.location', function () {
      // When the location changes, the VM sizes should
      // be refetched because not all sizes are available
      // in all regions.
      this.fetchVmSizes();
    }),
    tagsObserver: Ember.observer('tags', function () {
      const array = [];
      const tags = Ember.get(this, 'tags') || {};
      Object.keys(tags).forEach(key => {
        array.push(key);
        array.push(tags[key]);
      });
      Ember.set(this, 'config.tags', array.join(','));
    }),
    ipChoiceObserver: Ember.observer('publicIpChoice', function () {
      let publicIpChoice = Ember.get(this, 'publicIpChoice');

      if (Ember.get(this, 'publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        Ember.set(this, 'config.usePrivateIp', true);
      }
    }),
    publicIpObserver: Ember.observer('publicIpChoice', function () {
      let elChoice = Ember.get(this, 'publicIpChoice');
      let choice = Ember.get(this, 'publicIpChoices').findBy('value', elChoice);
      choice = choice.value.split(',');
      choice.forEach(val => {
        let tmp = val.split('=');
        Ember.set(this, `config.${tmp[0]}`, tmp[1] === 'true' ? true : false);
      });
    }),
    openPort: Ember.observer('openPorts', function () {
      let str = (Ember.get(this, 'openPorts') || '').trim();
      let ary = [];

      if (str.length) {
        ary = str.split(/\s*,\s*/);
      }

      Ember.set(this, 'config.openPort', ary);
    }),
    useAvailabilitySetObserver: Ember.observer('useAvailabilitySet', function () {
      // If the user switches between availability sets and availability zones,
      // the other value should be cleared out.
      if (Ember.get(this, 'useAvailabilitySet')) {
        Ember.set(this, 'config.availabilityZone', '');
      } else {
        const choices = Ember.get(this, 'availabilityZoneChoices');
        const selectedZone = Ember.get(this, 'config.availabilityZone');

        if (choices.length && !choices.findBy('value', selectedZone)) {
          Ember.set(this, 'config.availabilityZone', choices[0].value);
        }

        Ember.set(this, 'config.availabilitySet', '');
      }
    }),
    // if the list of az options changes and no longer includes whatever az has been configured, select the first available az from the new list
    availabilityZoneChoicesObserver: Ember.observer('availabilityZoneChoices', function () {
      const choices = Ember.get(this, 'availabilityZoneChoices');
      const isUsingZones = !Ember.get(this, 'useAvailabilitySet');
      const selectedZone = Ember.get(this, 'config.availabilityZone');

      if (choices.length && isUsingZones) {
        if (isUsingZones && !choices.findBy('value', selectedZone)) {
          Ember.set(this, 'config.availabilityZone', choices[0].value);
        }
      }
    }),
    // standard sku, public ip, managed disk must be set when using an availability zone
    availabilityZoneObserver: Ember.observer('config.availabilityZone', function () {
      if (Ember.get(this, 'config.availabilityZone')) {
        Ember.set(this, 'config.enablePublicIpStandardSku', true);
        Ember.set(this, 'managedDisks', MANAGED);
        Ember.set(this, 'publicIpChoice', IPCHOICES[0].value);
      }
    }),
    vmsWithAcceleratedNetworking: Ember.computed('vmSizes', function () {
      return Ember.get(this, 'vmSizes').filter(vmData => {
        return vmData.AcceleratedNetworkingSupported;
      });
    }),
    vmsWithoutAcceleratedNetworking: Ember.computed('vmSizes', function () {
      return Ember.get(this, 'vmSizes').filter(vmData => {
        return !vmData.AcceleratedNetworkingSupported;
      });
    }),
    selectedVmSizeExistsInSelectedRegion: Ember.computed('config.{location,size}', 'vmSizes', function () {
      // If the user selects a region and then a VM size
      // that does not exist in the region, the list of VM
      // sizes will update, causing the selected VM size
      // to disappear. A disappearing VM size seems like a
      // bad UX, so this value allows the value to be
      // added to the VM size dropdown, while an error message
      // indicates that the size is invalid.
      if (Ember.get(this, 'vmSizes').find(size => {
        return size.Name === Ember.get(this, 'config.size');
      })) {
        return true;
      }

      return false;
    }),
    vmsWithAvailabilityZones: Ember.computed('vmSizes', function () {
      return Ember.get(this, 'vmSizes').filter(vmData => {
        return vmData.AvailabilityZones.length > 0;
      });
    }),
    availabilityZoneChoices: Ember.computed('config.{location,size}', 'regions', 'vmSizes', function () {
      const vmData = Ember.get(this, 'vmSizes').find(vm => {
        return vm.Name === Ember.get(this, 'config.size');
      });

      if (vmData) {
        return vmData.AvailabilityZones.map(zone => {
          return {
            name: zone,
            value: zone
          };
        }).sort((a, b) => {
          return a.value - b.value;
        });
      }

      return [];
    }),
    availabilityZonesAreUnavailable: Ember.computed('availabilityZoneChoices', function () {
      return Ember.get(this, 'availabilityZoneChoices').length === 0;
    }),
    sizeChoices: Ember.computed('config.size', 'selectedVmSizeExistsInSelectedRegion', 'vmSizes', 'vmsWithAcceleratedNetworking', 'vmsWithoutAcceleratedNetworking', function () {
      // example vmSize option from backend:
      // {
      //   AcceleratedNetworkingSupported: false,
      //   AvailabilityZones: [],
      //   Name: "Basic_A0"
      // }
      const intl = window.l('service:intl');
      const noAnLabel = intl.t('nodeDriver.azure.size.doesNotSupportAcceleratedNetworking');
      const withAnLabel = intl.t('nodeDriver.azure.size.supportsAcceleratedNetworking');
      const vmsWithAn = Ember.get(this, 'vmsWithAcceleratedNetworking');
      const vmsWithoutAn = Ember.get(this, 'vmsWithoutAcceleratedNetworking');
      let out = [{
        kind: 'group',
        name: withAnLabel,
        value: withAnLabel,
        disabled: true
      }].concat(vmsWithAn).concat({
        kind: 'group',
        name: noAnLabel,
        value: noAnLabel,
        disabled: true
      }).concat(vmsWithoutAn);
      out = out.map(vmData => {
        const {
          Name
        } = vmData;

        if (vmData.kind === 'group') {
          return vmData;
        }

        return {
          label: Name,
          value: Name,
          disabled: vmData.disabled || false
        };
      });

      if (!Ember.get(this, 'selectedVmSizeExistsInSelectedRegion')) {
        return out.concat({
          label: Ember.get(this, 'config.size'),
          value: Ember.get(this, 'config.size'),
          disabled: true
        });
      }

      return out;
    }),
    privateSet: Ember.computed('publicIpChoice', 'publicIpChoices', function () {
      let publicIpChoice = Ember.get(this, 'publicIpChoice');

      if (publicIpChoice && Ember.get(this, 'publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        return true;
      }

      return false;
    }),
    setUsePrivateIp: Ember.computed('publicIpChoice', 'publicIpChoices', function () {
      let publicIpChoice = Ember.get(this, 'publicIpChoice');

      if (publicIpChoice && Ember.get(this, 'publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        return Ember.set(this, 'config.usePrivateIp', true);
      }

      return false;
    }),
    selectedVmSizeHasZones: Ember.computed('config.{location,size}', 'value.size', 'vmSizes', 'vmsWithAvailabilityZones', function () {
      const dataForSelectedSize = this.vmsWithAvailabilityZones.filter(vmData => {
        const {
          Name
        } = vmData;
        return Name === Ember.get(this, 'config.size');
      });

      if (dataForSelectedSize.length > 0) {
        return dataForSelectedSize[0].AvailabilityZones.length > 0;
      }

      return false;
    }),
    selectedVmSizeSupportsAN: Ember.computed('config.{location,size}', 'vmSizes', 'vmsWithAcceleratedNetworking', function () {
      const selectedSizeIsValid = !!this.vmsWithAcceleratedNetworking.find(vmData => {
        return Ember.get(this, 'config.size') === vmData.Name;
      });
      return selectedSizeIsValid;
    }),
    vmSizeAcceleratedNetworkingWarning: Ember.computed('config.{acceleratedNetworking,location,size}', 'selectedVmSizeSupportsAN', 'vmSizes.length', function () {
      if (Ember.get(this, 'vmSizes.length') === 0) {
        // Don't show the warning until the VM sizes are loaded
        return '';
      }

      const intl = window.l('service:intl');

      if (!this.selectedVmSizeSupportsAN && Ember.get(this, 'config.acceleratedNetworking')) {
        return intl.t('nodeDriver.azure.size.selectedSizeAcceleratedNetworkingWarning');
      }

      return '';
    }),
    vmSizeAvailabilityWarning: Ember.computed('config.{location,size}', 'regions.length', 'selectedVmSizeExistsInSelectedRegion', 'vmSizes.length', function () {
      if (Ember.get(this, 'regions.length') === 0) {
        // Don't show the warning until the regions are loaded
        return '';
      }

      if (Ember.get(this, 'vmSizes.length') === 0) {
        // Don't show the warning until the VM sizes are loaded
        return '';
      }

      const intl = window.l('service:intl');

      if (!Ember.get(this, 'selectedVmSizeExistsInSelectedRegion')) {
        return intl.t('nodeDriver.azure.size.availabilityWarning');
      }

      return '';
    }),
    vmAvailabilityZoneWarning: Ember.computed('config.{location,size}', 'selectedVmSizeHasZones', 'useAvailabilitySet', 'value.size', 'vmSizes.length', 'vmsWithAvailabilityZones.length', function () {
      if (this.useAvailabilitySet) {
        return '';
      }

      if (Ember.get(this, 'vmSizes.length') === 0) {
        // Don't show the warning until the VM sizes are loaded
        return '';
      }

      const intl = window.l('service:intl');

      if (this.vmsWithAvailabilityZones.length === 0) {
        /**
         * Show UI warning: Availability zones are not supported in the selected
         * region. Please select a different region or use an
         * availability set instead.
         */
        return intl.t('nodeDriver.azure.size.regionDoesNotSupportAzs');
      }

      if (this.vmsWithAvailabilityZones.length > 0 && !this.selectedVmSizeHasZones) {
        /**
         * Show UI warning: The selected region does not support availability
         * zones for the selected VM size. Please select a
         * different region or VM size.
         */
        return intl.t('nodeDriver.azure.size.regionSupportsAzsButNotThisSize');
      }

      return '';
    }),

    fetchRegions() {
      const store = Ember.get(this, 'globalStore');
      const regions = (0, _util.addQueryParams)('/meta/aksLocations', {
        cloudCredentialId: this.cloudCredentialId
      });
      const aksRequest = {
        regions: store.rawRequest({
          url: regions,
          method: 'GET'
        })
      };
      Ember.RSVP.hash(aksRequest).then(resp => {
        const {
          regions
        } = resp;
        Ember.setProperties(this, {
          regions: (regions === null || regions === void 0 ? void 0 : regions.body) || [],
          regionsLoading: false
        });
      }).catch(xhr => {
        var _xhr$body, _xhr$body2, _xhr$body3;

        const err = ((_xhr$body = xhr.body) === null || _xhr$body === void 0 ? void 0 : _xhr$body.message) || ((_xhr$body2 = xhr.body) === null || _xhr$body2 === void 0 ? void 0 : _xhr$body2.code) || ((_xhr$body3 = xhr.body) === null || _xhr$body3 === void 0 ? void 0 : _xhr$body3.error);
        Ember.setProperties(this, {
          errors: [err]
        });
      });
    },

    fetchVmSizes() {
      const store = Ember.get(this, 'globalStore');
      const vmSizes = (0, _util.addQueryParams)('/meta/aksVMSizesV2', {
        cloudCredentialId: this.cloudCredentialId,
        region: Ember.get(this, 'config.location')
      });
      const aksRequest = {
        vmSizes: store.rawRequest({
          url: vmSizes,
          method: 'GET'
        })
      };
      Ember.RSVP.hash(aksRequest).then(resp => {
        const {
          vmSizes
        } = resp;
        Ember.setProperties(this, {
          vmSizes: (vmSizes === null || vmSizes === void 0 ? void 0 : vmSizes.body) || [],
          vmSizesLoading: false
        });
      }).catch(xhr => {
        const err = xhr.body.message || xhr.body.code || xhr.body.error;
        Ember.setProperties(this, {
          errors: [err]
        });
      });
    },

    bootstrap() {
      let config = Ember.get(this, 'globalStore').createRecord({
        type: CONFIG,
        subscriptionId: '',
        tags: '',
        openPort: ['6443/tcp', '2379/tcp', '2380/tcp', '8472/udp', '4789/udp', '9796/tcp', '10256/tcp', '10250/tcp', '10251/tcp', '10252/tcp']
      });
      Ember.set(this, `model.${CONFIG}`, config);
    },

    initOpenPorts(ports) {
      return ports ? ports.join(',') : '';
    },

    initPublicIpChoices(staticPublicIp, noPublicIp) {
      if (staticPublicIp && noPublicIp) {
        return Ember.get(this, 'publicIpChoices').findBy('name', 'None').value;
      } else if (staticPublicIp && !noPublicIp) {
        return Ember.get(this, 'publicIpChoices').findBy('name', 'Static').value;
      } else {
        return Ember.get(this, 'publicIpChoices').findBy('name', 'Dynamic').value;
      }
    },

    validate() {
      this._super();

      let errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'model.name')) {
        errors.push(this.intl.t('nodeDriver.nameError'));
      }

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    },

    setupComponent() {
      Ember.setProperties(this, {
        publicIpChoice: this.initPublicIpChoices(Ember.get(this, 'config.staticPublicIp'), Ember.get(this, 'config.noPublicIp')),
        openPorts: this.initOpenPorts(Ember.get(this, 'config.openPort')),
        managedDisks: Ember.get(this, 'config.managedDisks') ? MANAGED : UNMANAGED
      });

      if (!this.editing) {
        Ember.set(this, 'config.nsg', `rancher-managed-${(0, _util.randomStr)(8, 8)}`);
      }
    }

  });

  _exports.default = _default;
});