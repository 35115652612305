define("nodes/components/driver-harvester/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-harvester/template", "js-yaml", "ui/utils/debounce", "yaml"], function (_exports, _nodeDriver, _template, _jsYaml, _debounce, _yaml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.QGA_JSON = void 0;
  const DRIVER = 'harvester';
  const CONFIG = 'harvesterConfig';
  const SYSTEM_NAMESPACES = ['cattle-dashboards', 'cattle-global-data', 'cattle-system', 'gatekeeper-system', 'ingress-nginx', 'kube-node-lease', 'kube-public', 'kube-system', 'linkerd', 'rio-system', 'security-scan', 'tekton-pipelines'];
  const TYPE = {
    AFFINITY: 'affinity',
    ANTI_AFFINITY: 'antiAffinity'
  };
  const PRIORITY = {
    REQUIRED: 'required',
    PREFERRED: 'preferred'
  };
  const STORAGE_NETWORK = 'storage-network.settings.harvesterhci.io'; // init qemu guest agent

  const QGA_JSON = {
    package_update: true,
    packages: ['qemu-guest-agent'],
    runcmd: [['systemctl', 'enable', '--now', 'qemu-guest-agent.service']]
  };
  _exports.QGA_JSON = QGA_JSON;

  var _default = Ember.Component.extend(_nodeDriver.default, {
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    driverName: DRIVER,
    model: {},
    currentCluster: null,
    clusters: [],
    clusterContent: [],
    imageContent: [],
    networkContent: [],
    namespaceContent: [],
    nodes: [],
    namespaces: [],
    nodeSchedulings: [],
    podSchedulings: [],
    networkDataContent: [],
    storageClassContent: [],
    defaultStorageClass: '',
    userDataContent: [],
    controller: null,
    signal: '',
    isImportMode: true,
    loading: false,
    disks: [],
    interfaces: [],
    installAgent: false,
    userDataTemplate: '',
    config: Ember.computed.alias(`model.${CONFIG}`),

    init() {
      this._super(...arguments);

      const controller = new AbortController();
      Ember.set(this, 'controller', controller);
      this.fetchResource();

      if (!!Ember.get(this, 'config.vmAffinity')) {
        this.initSchedulings();
      }

      this.initUserData();
      this.initDisks();
      this.initInterfaces();
    },

    actions: {
      async finishAndSelectCloudCredential(credential) {
        await this.globalStore.findAll('cloudcredential', {
          forceReload: true
        });
        Ember.set(this, 'model.cloudCredentialId', Ember.get(credential, 'id'));
      },

      updateYaml(type, value) {
        Ember.set(this, `config.${type}`, value);
      },

      addNodeScheduling() {
        const neu = {
          priority: PRIORITY.REQUIRED,
          nodeSelectorTerms: {
            matchExpressions: []
          }
        };
        this.get('nodeSchedulings').pushObject(neu);
      },

      addVolume(type) {
        let neu = {};

        if (type === 'volume') {
          neu = {
            storageClassName: Ember.get(this, 'defaultStorageClass'),
            size: 10,
            bootOrder: 0
          };
        } else if (type === 'image') {
          neu = {
            imageName: '',
            size: 40,
            bootOrder: 0
          };
        }

        this.get('disks').pushObject(neu);
      },

      addNetwork() {
        const neu = {
          networkName: '',
          macAddress: ''
        };
        this.get('interfaces').pushObject(neu);
      },

      removeNodeScheduling(scheduling) {
        this.get('nodeSchedulings').removeObject(scheduling);
      },

      removeDisk(disk) {
        this.get('disks').removeObject(disk);
      },

      removeNetwork(network) {
        this.get('interfaces').removeObject(network);
      },

      updateNodeScheduling() {
        this.parseNodeScheduling();
      },

      addPodScheduling() {
        const neu = {
          type: TYPE.AFFINITY,
          priority: PRIORITY.REQUIRED,
          labelSelector: {
            matchExpressions: []
          },
          topologyKey: ''
        };
        this.get('podSchedulings').pushObject(neu);
      },

      removePodScheduling(scheduling) {
        this.get('podSchedulings').removeObject(scheduling);
      },

      updatePodScheduling() {
        this.parsePodScheduling();
      },

      updateAgent() {
        const isInstall = !Ember.get(this, 'installAgent');
        Ember.set(this, 'installAgent', isInstall);
        const userData = Ember.get(this, 'config.userData');
        const userDataDoc = isInstall ? this.addGuestAgent(userData) : this.deleteGuestAgent(userData);
        let userDataYaml = userDataDoc.toString();

        if (userDataYaml === '{}\n') {
          // When the YAML parsed value is '{}\n', it means that the userData is empty.
          userDataYaml = '';
        }

        const hasCloudComment = this.hasCloudConfigComment(userDataYaml);

        if (!hasCloudComment) {
          userDataYaml = `#cloud-config\n${userDataYaml}`;
        }

        Ember.set(this, 'config.userData', userDataYaml);
      },

      chooseUserDataTemplate() {
        const templateValue = Ember.get(this, 'userDataTemplate');
        const isInstallAgent = Ember.get(this, 'installAgent');

        try {
          const templateJsonData = this.convertToJson(templateValue);
          let userDataYaml;

          if (isInstallAgent) {
            const mergedObj = Object.assign(templateJsonData, { ...QGA_JSON
            });
            userDataYaml = this.addCloudConfigComment(mergedObj);
          } else {
            userDataYaml = templateValue;
          }

          Ember.set(this, 'config.userData', userDataYaml);
        } catch (e) {
          const message = this.intl.t('nodeDriver.harvester.templateError');
          Ember.get(this, 'growl').fromError(undefined, message);
        }
      }

    },
    clearData: Ember.observer('currentCredential.id', function () {
      Ember.set(this, 'config.imageName', '');
      Ember.set(this, 'config.networkName', '');
      Ember.set(this, 'config.vmNamespace', '');
      Ember.set(this, 'nodeSchedulings', []);
      Ember.set(this, 'podSchedulings', []);
      Ember.set(this, 'vmAffinity', {});
      Ember.set(this, 'config.vmAffinity', '');
      Ember.set(this, 'config.diskInfo', '');
      Ember.set(this, 'config.networkInfo', '');
      this.initUserData();
      this.initDisks();
      this.initInterfaces();
    }),
    setDiskInfo: Ember.observer('disks.@each.{imageName,bootOrder,storageClassName,size}', function () {
      const diskInfo = {
        disks: Ember.get(this, 'disks').map(disk => {
          return { ...disk,
            size: Number(disk.size)
          };
        })
      };
      Ember.set(this, 'config.diskInfo', JSON.stringify(diskInfo));
    }),
    setNetworkInfo: Ember.observer('interfaces.@each.{networkName,macAddress}', function () {
      const networkInfo = {
        interfaces: Ember.get(this, 'interfaces')
      };
      Ember.set(this, 'config.networkInfo', JSON.stringify(networkInfo));
    }),
    nodeSchedulingsChanged: Ember.observer('nodeSchedulings.[]', function () {
      this.parseNodeScheduling();
    }),
    podSchedulingsChanged: Ember.observer('podSchedulings.[]', function () {
      this.parsePodScheduling();
    }),
    userDataChanged: Ember.observer('config.userData', function () {
      const userData = Ember.get(this, 'config.userData');
      const installAgent = Ember.get(this, 'installAgent');
      const hasInstall = this.hasInstallAgent(userData, installAgent);
      Ember.set(this, 'installAgent', hasInstall);
    }),
    fetchResource: (0, _debounce.throttledObserver)('currentCredential.id', 'currentCredential.harvestercredentialConfig.clusterId', async function () {
      const clusterId = Ember.get(this, 'currentCredential') && Ember.get(this, 'currentCredential').harvestercredentialConfig && Ember.get(this, 'currentCredential').harvestercredentialConfig.clusterId;
      const url = clusterId === 'local' ? '' : `/k8s/clusters/${clusterId}`;

      if (!clusterId) {
        return;
      }

      let controller = Ember.get(this, 'controller');
      let signal = Ember.get(this, 'signal');
      signal = controller.signal;
      Ember.set(this, 'signal', signal);
      Ember.set(this, 'loading', true);
      Ember.RSVP.hash({
        nodes: Ember.get(this, 'globalStore').rawRequest({
          url: `${url}/v1/node`
        })
      }).then(resp => {
        Ember.set(this, 'nodes', resp.nodes.body.data || []);
      }).catch(err => {
        const message = err.statusText || err.message;
        Ember.set(this, 'nodes', []);
        Ember.get(this, 'growl').fromError('Error request Node API', message);
      });
      Ember.RSVP.hash({
        images: Ember.get(this, 'globalStore').rawRequest({
          url: `${url}/v1/harvesterhci.io.virtualmachineimages`
        }),
        networks: Ember.get(this, 'globalStore').rawRequest({
          url: `${url}/v1/k8s.cni.cncf.io.networkattachmentdefinition`
        }),
        namespaces: Ember.get(this, 'globalStore').rawRequest({
          url: `${url}/v1/namespace`
        }),
        configmaps: Ember.get(this, 'globalStore').rawRequest({
          url: `${url}/v1/configmap`
        }),
        storageClass: Ember.get(this, 'globalStore').rawRequest({
          url: `${url}/v1/storage.k8s.io.storageclasses`
        }),
        systemNamespace: Ember.get(this, 'globalStore').rawRequest({
          url: `${url}/v1/management.cattle.io.settings/system-namespaces`
        })
      }).then(resp => {
        const images = resp.images.body.data || [];
        const imageContent = images.filter(O => {
          return !O.spec.url.endsWith('.iso') && this.isReady.call(O);
        }).map(O => {
          const value = O.id;
          const label = `${O.spec.displayName} (${value})`;
          return {
            label,
            value
          };
        });
        const networks = resp.networks.body.data || [];
        const networkContent = networks.filter(O => {
          var _O$metadata, _O$metadata$annotatio;

          return ((_O$metadata = O.metadata) === null || _O$metadata === void 0 ? void 0 : (_O$metadata$annotatio = _O$metadata.annotations) === null || _O$metadata$annotatio === void 0 ? void 0 : _O$metadata$annotatio[STORAGE_NETWORK]) !== 'true';
        }).map(O => {
          let id = '';

          try {
            const config = JSON.parse(O.spec.config);
            id = config.vlan;
          } catch (err) {
            Ember.get(this, 'growl').fromError('Error parse network config', err);
          }

          const value = O.id;
          const label = `${value} (vlanId=${id})`;
          return {
            label,
            value
          };
        });
        const systemNamespaceValue = resp.systemNamespace.body.value || '';
        const systemNamespaces = systemNamespaceValue.split(',');
        const namespaces = resp.namespaces.body.data || [];
        const namespaceContent = namespaces.filter(O => {
          return !this.isSystemNamespace(O) && O.links.update && !systemNamespaces.includes(O.metadata.name);
        }).map(O => {
          const value = O.id;
          const label = O.id;
          return {
            label,
            value
          };
        });
        const configmaps = resp.configmaps.body.data || [];
        const networkDataContent = [];
        const userDataContent = [];
        configmaps.map(O => {
          const cloudTemplate = O.metadata && O.metadata.labels && O.metadata.labels['harvesterhci.io/cloud-init-template'];
          const value = O.data && O.data.cloudInit;
          const label = O.metadata.name;

          if (cloudTemplate === 'user') {
            userDataContent.push({
              label,
              value
            });
          } else if (cloudTemplate === 'network') {
            networkDataContent.push({
              label,
              value
            });
          }
        });
        const storageClass = resp.storageClass.body.data || [];
        let defaultStorageClass = '';
        const storageClassContent = storageClass.filter(s => {
          var _s$parameters;

          return !((_s$parameters = s.parameters) !== null && _s$parameters !== void 0 && _s$parameters.backingImage);
        }).map(s => {
          var _s$metadata, _s$metadata$annotatio;

          const isDefault = ((_s$metadata = s.metadata) === null || _s$metadata === void 0 ? void 0 : (_s$metadata$annotatio = _s$metadata.annotations) === null || _s$metadata$annotatio === void 0 ? void 0 : _s$metadata$annotatio['storageclass.kubernetes.io/is-default-class']) === 'true';
          const label = isDefault ? `${s.metadata.name} (${this.intl.t('generic.default')})` : s.metadata.name;

          if (isDefault) {
            defaultStorageClass = s.metadata.name;
          }

          return {
            label,
            value: s.metadata.name
          };
        }) || [];
        Ember.setProperties(this, {
          imageContent,
          networkContent,
          namespaceContent,
          userDataContent,
          networkDataContent,
          storageClassContent,
          defaultStorageClass
        });
      }).catch(err => {
        Ember.setProperties(this, {
          imageContent: [],
          networkContent: [],
          namespaceContent: [],
          userDataContent: [],
          networkDataContent: [],
          namespaces: [],
          vmAffinity: [],
          nodeSchedulings: [],
          podSchedulings: [],
          storageClassContent: []
        });
        const message = err.statusText || err.message;
        Ember.get(this, 'growl').fromError('Error request Image API', message);
      }).finally(() => {
        Ember.set(this, 'loading', false);
      });
      controller.abort();
    }),
    harvestercredentialConfig: Ember.computed('cloudCredentials.@each.harvestercredentialConfig', function () {
      return (Ember.get(this, 'cloudCredentials') || []).mapBy('harvestercredentialConfig');
    }),
    currentCredential: Ember.computed('cloudCredentials', 'harvestercredentialConfig.[]', 'model.cloudCredentialId', function () {
      return (Ember.get(this, 'cloudCredentials') || []).find(C => C.id === Ember.get(this, 'model.cloudCredentialId'));
    }),

    isSystemNamespace(namespace) {
      if (namespace.metadata && namespace.metadata.annotations && namespace.metadata.annotations['management.cattle.io/system-namespace'] === 'true') {
        return true;
      }

      if (namespace.metadata.labels['fleet.cattle.io/managed'] === 'true') {
        return true;
      }

      if (SYSTEM_NAMESPACES.includes(namespace.metadata.name)) {
        return true;
      }

      if (namespace.metadata && namespace.metadata.name && namespace.metadata.name.endsWith('-system')) {
        return true;
      }

      return false;
    },

    bootstrap() {
      let config = Ember.get(this, 'globalStore').createRecord({
        type: CONFIG,
        cpuCount: 2,
        memorySize: 4,
        diskSize: 40,
        diskBus: 'virtio',
        imageName: '',
        sshUser: '',
        networkName: '',
        networkData: '',
        vmNamespace: '',
        userData: '',
        vmAffinity: '',
        diskInfo: '',
        networkInfo: ''
      });
      Ember.set(this, `model.${CONFIG}`, config);
    },

    addGuestAgent(userData) {
      const userDataDoc = userData ? _yaml.default.parseDocument(userData) : _yaml.default.parseDocument({});
      const userDataYAML = userDataDoc.toString();

      const userDataJSON = _yaml.default.parse(userDataYAML);

      let packages = (userDataJSON === null || userDataJSON === void 0 ? void 0 : userDataJSON.packages) || [];
      let runcmd = (userDataJSON === null || userDataJSON === void 0 ? void 0 : userDataJSON.runcmd) || [];
      userDataDoc.setIn(['package_update'], true);

      if (Array.isArray(packages)) {
        if (!packages.includes('qemu-guest-agent')) {
          packages.push('qemu-guest-agent');
        }
      } else {
        packages = QGA_JSON.packages;
      }

      if (Array.isArray(runcmd)) {
        const hasSameRuncmd = runcmd.find(S => Array.isArray(S) && S.join('-') === QGA_JSON.runcmd[0].join('-'));

        if (!hasSameRuncmd) {
          runcmd.push(QGA_JSON.runcmd[0]);
        }
      } else {
        runcmd = QGA_JSON.runcmd;
      }

      if (packages.length > 0) {
        userDataDoc.setIn(['packages'], packages);
      } else {
        userDataDoc.setIn(['packages'], []); // It needs to be set empty first, as it is possible that cloud-init comments are mounted on this node

        this.deleteYamlDocProp(userDataDoc, ['packages']);
        this.deleteYamlDocProp(userDataDoc, ['package_update']);
      }

      if (runcmd.length > 0) {
        userDataDoc.setIn(['runcmd'], runcmd);
      } else {
        this.deleteYamlDocProp(userDataDoc, ['runcmd']);
      }

      return userDataDoc;
    },

    deleteGuestAgent(userData) {
      const userDataDoc = userData ? _yaml.default.parseDocument(userData) : _yaml.default.parseDocument({});
      const userDataYAML = userDataDoc.toString();

      const userDataJSON = _yaml.default.parse(userDataYAML);

      const packages = (userDataJSON === null || userDataJSON === void 0 ? void 0 : userDataJSON.packages) || [];
      const runcmd = (userDataJSON === null || userDataJSON === void 0 ? void 0 : userDataJSON.runcmd) || [];

      if (Array.isArray(packages)) {
        for (let i = 0; i < packages.length; i++) {
          if (packages[i] === 'qemu-guest-agent') {
            packages.splice(i, 1);
          }
        }
      }

      if (Array.isArray(runcmd)) {
        for (let i = 0; i < runcmd.length; i++) {
          if (Array.isArray(runcmd[i]) && runcmd[i].join('-') === QGA_JSON.runcmd[0].join('-')) {
            runcmd.splice(i, 1);
          }
        }
      }

      if (packages.length > 0) {
        userDataDoc.setIn(['packages'], packages);
      } else {
        userDataDoc.setIn(['packages'], []);
        this.deleteYamlDocProp(userDataDoc, ['packages']);
        this.deleteYamlDocProp(userDataDoc, ['package_update']);
      }

      if (runcmd.length > 0) {
        userDataDoc.setIn(['runcmd'], runcmd);
      } else {
        this.deleteYamlDocProp(userDataDoc, ['runcmd']);
      }

      return userDataDoc;
    },

    hasCloudConfigComment(userScript) {
      var _userDataDoc$contents, _userDataDoc$comment, _userDataDoc$commentB;

      // Check that userData contains: #cloud-config
      const userDataDoc = userScript ? _yaml.default.parseDocument(userScript) : _yaml.default.parseDocument({});
      const items = (userDataDoc === null || userDataDoc === void 0 ? void 0 : (_userDataDoc$contents = userDataDoc.contents) === null || _userDataDoc$contents === void 0 ? void 0 : _userDataDoc$contents.items) || [];
      let exist = false;

      if ((userDataDoc === null || userDataDoc === void 0 ? void 0 : userDataDoc.comment) === 'cloud-config' || userDataDoc !== null && userDataDoc !== void 0 && (_userDataDoc$comment = userDataDoc.comment) !== null && _userDataDoc$comment !== void 0 && _userDataDoc$comment.includes('cloud-config\n')) {
        exist = true;
      }

      if ((userDataDoc === null || userDataDoc === void 0 ? void 0 : userDataDoc.commentBefore) === 'cloud-config' || userDataDoc !== null && userDataDoc !== void 0 && (_userDataDoc$commentB = userDataDoc.commentBefore) !== null && _userDataDoc$commentB !== void 0 && _userDataDoc$commentB.includes('cloud-config\n')) {
        exist = true;
      }

      items.map(item => {
        var _key$commentBefore, _key$commentBefore2;

        const key = item.key;

        if ((key === null || key === void 0 ? void 0 : (_key$commentBefore = key.commentBefore) === null || _key$commentBefore === void 0 ? void 0 : _key$commentBefore.trim()) === 'cloud-config' || key !== null && key !== void 0 && (_key$commentBefore2 = key.commentBefore) !== null && _key$commentBefore2 !== void 0 && _key$commentBefore2.includes('cloud-config\n') || /\ncloud-config$/.test(key === null || key === void 0 ? void 0 : key.commentBefore)) {
          exist = true;
        }
      });
      return exist;
    },

    deleteYamlDocProp(doc, paths) {
      try {
        var _doc$getIn, _key$commentBefore3;

        const item = (_doc$getIn = doc.getIn([])) === null || _doc$getIn === void 0 ? void 0 : _doc$getIn.items[0];
        const key = item === null || item === void 0 ? void 0 : item.key;
        const hasCloudConfigComment = !!(key !== null && key !== void 0 && (_key$commentBefore3 = key.commentBefore) !== null && _key$commentBefore3 !== void 0 && _key$commentBefore3.includes('cloud-config'));
        const isMatchProp = key.source === paths[paths.length - 1];

        if (key && hasCloudConfigComment && isMatchProp) {// Comments are mounted on the next node and we should not delete the node containing cloud-config
        } else {
          doc.deleteIn(paths);
        }
      } catch (e) {}
    },

    validate() {
      this._super();

      let errors = Ember.get(this, 'errors') || [];

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (!Ember.get(this, 'config.vmNamespace')) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('nodeDriver.harvester.namespace.label')
        }));
      }

      if (!Ember.get(this, 'config.sshUser')) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('nodeDriver.harvester.sshUser.label')
        }));
      }

      this.validateScheduling(errors);
      this.validateDiskAndNetwork(errors); // Set the array of errors for display,
      // and return true if saving should continue.

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    },

    isReady() {
      var _this$status;

      function getStatusConditionOfType(type, defaultValue = []) {
        const conditions = Array.isArray(Ember.get(this, 'status.conditions')) ? this.status.conditions : defaultValue;
        return conditions.find(cond => cond.type === type);
      }

      const initialized = getStatusConditionOfType.call(this, 'Initialized');
      const imported = getStatusConditionOfType.call(this, 'Imported');
      const isCompleted = ((_this$status = this.status) === null || _this$status === void 0 ? void 0 : _this$status.progress) === 100;

      if ([initialized === null || initialized === void 0 ? void 0 : initialized.status, imported === null || imported === void 0 ? void 0 : imported.status].includes('False')) {
        return false;
      } else {
        return isCompleted && true;
      }
    },

    isEmptyObject(obj) {
      return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
    },

    isImageVolume(volume) {
      return Object.prototype.hasOwnProperty.call(volume, 'imageName');
    },

    initSchedulings() {
      const nodeSchedulings = [];
      const podSchedulings = [];
      const parsedObj = JSON.parse(AWS.util.base64.decode(Ember.get(this, 'config.vmAffinity')).toString());
      const nodeAffinityRequired = Ember.get(parsedObj, 'nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution');
      const nodeAffinityPreferred = Ember.get(parsedObj, 'nodeAffinity.preferredDuringSchedulingIgnoredDuringExecution');
      const podAffinityRequired = Ember.get(parsedObj, 'podAffinity.requiredDuringSchedulingIgnoredDuringExecution');
      const podAffinityPreferred = Ember.get(parsedObj, 'podAffinity.preferredDuringSchedulingIgnoredDuringExecution');
      const podAntiAffinityRequired = Ember.get(parsedObj, 'podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution');
      const podAntiAffinityPreferred = Ember.get(parsedObj, 'podAntiAffinity.preferredDuringSchedulingIgnoredDuringExecution');

      if (nodeAffinityRequired) {
        nodeAffinityRequired.nodeSelectorTerms.forEach(S => {
          nodeSchedulings.push({
            priority: PRIORITY.REQUIRED,
            nodeSelectorTerms: {
              matchExpressions: S.matchExpressions
            }
          });
        });
      }

      if (nodeAffinityPreferred) {
        nodeAffinityPreferred.forEach(S => {
          nodeSchedulings.push({
            priority: PRIORITY.PREFERRED,
            nodeSelectorTerms: {
              matchExpressions: S.preference.matchExpressions
            }
          });
        });
      }

      if (podAffinityRequired) {
        podAffinityRequired.forEach(S => {
          podSchedulings.push({
            type: TYPE.AFFINITY,
            priority: PRIORITY.REQUIRED,
            labelSelector: {
              matchExpressions: S.labelSelector.matchExpressions
            },
            namespaceSelector: S.namespaceSelector || null,
            topologyKey: S.topologyKey,
            namespaces: S.namespaces || null,
            weight: S.weight || ''
          });
        });
      }

      if (podAffinityPreferred) {
        podAffinityPreferred.forEach(S => {
          podSchedulings.push({
            type: TYPE.AFFINITY,
            priority: PRIORITY.PREFERRED,
            labelSelector: {
              matchExpressions: S.podAffinityTerm.labelSelector.matchExpressions
            },
            topologyKey: S.podAffinityTerm.topologyKey,
            namespaceSelector: S.podAffinityTerm.namespaceSelector || null,
            namespaces: Ember.get(S, 'podAffinityTerm.namespaces') || null,
            weight: Ember.get(S, 'podAffinityTerm.weight') || ''
          });
        });
      }

      if (podAntiAffinityRequired) {
        podAntiAffinityRequired.forEach(S => {
          podSchedulings.push({
            type: TYPE.ANTI_AFFINITY,
            priority: PRIORITY.REQUIRED,
            labelSelector: {
              matchExpressions: S.labelSelector.matchExpressions
            },
            namespaceSelector: S.namespaceSelector || null,
            topologyKey: S.topologyKey,
            namespaces: S.namespaces || null,
            weight: S.weight || ''
          });
        });
      }

      if (podAntiAffinityPreferred) {
        podAntiAffinityPreferred.forEach(S => {
          podSchedulings.push({
            type: TYPE.ANTI_AFFINITY,
            priority: PRIORITY.PREFERRED,
            labelSelector: {
              matchExpressions: S.podAffinityTerm.labelSelector.matchExpressions
            },
            namespaceSelector: S.podAffinityTerm.namespaceSelector || null,
            topologyKey: S.podAffinityTerm.topologyKey,
            namespaces: Ember.get(S, 'podAffinityTerm.namespaces') || null,
            weight: Ember.get(S, 'podAffinityTerm.weight') || ''
          });
        });
      }

      Ember.set(this, 'nodeSchedulings', nodeSchedulings);
      Ember.set(this, 'podSchedulings', podSchedulings);
    },

    initUserData() {
      if (!Ember.get(this, 'config.userData')) {
        let userData = this.addCloudConfigComment(QGA_JSON);
        Ember.set(this, 'config.userData', userData);
      }

      const userData = Ember.get(this, 'config.userData');
      const hasInstall = this.hasInstallAgent(userData, true);
      Ember.set(this, 'installAgent', hasInstall);
    },

    initDisks() {
      let disks = [];

      if (!Ember.get(this, 'config.diskInfo')) {
        const imageName = Ember.get(this, 'config.imageName') || '';
        disks = [{
          imageName,
          bootOrder: 1,
          size: 40
        }];

        if (Ember.get(this, 'config.diskBus')) {
          disks[0].bus = Ember.get(this, 'config.diskBus');
        }

        const diskInfo = JSON.stringify({
          disks
        });
        Ember.set(this, 'config.diskInfo', diskInfo);
      } else {
        const diskInfo = Ember.get(this, 'config.diskInfo');
        disks = JSON.parse(diskInfo).disks || [];
      }

      Ember.set(this, 'disks', disks);
    },

    convertToJson(script = '') {
      let out = {};

      try {
        out = _jsYaml.default.load(script);
      } catch (e) {
        throw new Error('Function(convertToJson) error');
      }

      return out;
    },

    hasInstallAgent(userScript, installAgent) {
      var _dataFormat, _dataFormat$packages, _dataFormat2, _dataFormat2$runcmd;

      let dataFormat = {};

      try {
        dataFormat = this.convertToJson(userScript);
      } catch {
        // When the yaml cannot be converted to json, the previous installAgent value should be returned
        return installAgent;
      }

      const hasInstall = ((_dataFormat = dataFormat) === null || _dataFormat === void 0 ? void 0 : (_dataFormat$packages = _dataFormat.packages) === null || _dataFormat$packages === void 0 ? void 0 : _dataFormat$packages.includes('qemu-guest-agent')) && !!((_dataFormat2 = dataFormat) !== null && _dataFormat2 !== void 0 && (_dataFormat2$runcmd = _dataFormat2.runcmd) !== null && _dataFormat2$runcmd !== void 0 && _dataFormat2$runcmd.find(S => Array.isArray(S) && S.join('-') === QGA_JSON.runcmd[0].join('-')));
      return !!hasInstall;
    },

    addCloudConfigComment(value) {
      if (typeof value === 'object' && value !== null) {
        return `#cloud-config\n${_jsYaml.default.safeDump(value)}`;
      } else if (typeof value === 'string' && !value.startsWith('#cloud-config')) {
        return `#cloud-config\n${value}`;
      } else if (typeof value === 'string') {
        return value;
      }
    },

    initInterfaces() {
      let _interfaces = [];

      if (!Ember.get(this, 'config.networkInfo')) {
        const networkName = Ember.get(this, 'config.networkName') || '';
        _interfaces = [{
          networkName,
          macAddress: ''
        }];

        if (Ember.get(this, 'config.networkModel')) {
          _interfaces[0].model = Ember.get(this, 'config.networkModel');
        }

        const networkInfo = JSON.stringify({
          interfaces: _interfaces
        });
        Ember.set(this, 'config.networkInfo', networkInfo);
      } else {
        const networkInfo = Ember.get(this, 'config.networkInfo');
        _interfaces = JSON.parse(networkInfo).interfaces || [];
      }

      Ember.set(this, 'interfaces', _interfaces);
    },

    parseNodeScheduling() {
      const arr = this.nodeSchedulings;
      const out = {};

      if (arr.find(S => S.priority === PRIORITY.REQUIRED)) {
        out.requiredDuringSchedulingIgnoredDuringExecution = {
          nodeSelectorTerms: []
        };
      }

      if (arr.find(S => S.priority === PRIORITY.PREFERRED)) {
        out.preferredDuringSchedulingIgnoredDuringExecution = [];
      }

      arr.forEach(S => {
        if (S.priority === PRIORITY.REQUIRED) {
          out.requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms.push({
            matchExpressions: S.nodeSelectorTerms.matchExpressions
          });
        }

        if (S.priority === PRIORITY.PREFERRED) {
          out.preferredDuringSchedulingIgnoredDuringExecution.push({
            preference: {
              matchExpressions: S.nodeSelectorTerms.matchExpressions
            }
          });
        }
      });
      const parseObj = { ...Ember.get(this, 'vmAffinity')
      };

      if (!this.isEmptyObject(out)) {
        Ember.set(parseObj, 'nodeAffinity', out);
      } else {
        delete parseObj.nodeAffinity;
      }

      Ember.set(this, 'config.vmAffinity', this.isEmptyObject(parseObj) ? '' : AWS.util.base64.encode(JSON.stringify(parseObj)));
      Ember.set(this, 'vmAffinity', parseObj);
    },

    parsePodScheduling() {
      const arr = this.podSchedulings;
      const out = {};

      if (arr.find(S => S.type === TYPE.AFFINITY)) {
        out.podAffinity = {};
      }

      if (arr.find(S => S.type === TYPE.ANTI_AFFINITY)) {
        out.podAntiAffinity = {};
      }

      if (arr.find(S => S.type === TYPE.AFFINITY && S.priority === PRIORITY.REQUIRED)) {
        out.podAffinity.requiredDuringSchedulingIgnoredDuringExecution = [];
      }

      if (arr.find(S => S.type === TYPE.AFFINITY && S.priority === PRIORITY.PREFERRED)) {
        out.podAffinity.preferredDuringSchedulingIgnoredDuringExecution = [];
      }

      if (arr.find(S => S.type === TYPE.ANTI_AFFINITY && S.priority === PRIORITY.REQUIRED)) {
        out.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution = [];
      }

      if (arr.find(S => S.type === TYPE.ANTI_AFFINITY && S.priority === PRIORITY.PREFERRED)) {
        out.podAntiAffinity.preferredDuringSchedulingIgnoredDuringExecution = [];
      }

      arr.forEach(S => {
        const requiredObj = {
          labelSelector: S.labelSelector,
          topologyKey: S.topologyKey
        };
        const preferredObj = {
          podAffinityTerm: {
            labelSelector: S.labelSelector,
            topologyKey: S.topologyKey
          }
        };

        if (S.namespaces && S.namespaces.length > 0) {
          requiredObj.namespaces = S.namespaces;
          preferredObj.podAffinityTerm.namespaces = S.namespaces;
        }

        if (S.weight) {
          requiredObj.weight = S.weight;
          preferredObj.weight = S.weight;
        }

        if (S.namespaceSelector) {
          requiredObj.namespaceSelector = S.namespaceSelector;
          preferredObj.podAffinityTerm.namespaceSelector = S.namespaceSelector;
        }

        if (S.type === TYPE.AFFINITY && S.priority === PRIORITY.REQUIRED) {
          out.podAffinity.requiredDuringSchedulingIgnoredDuringExecution.push(requiredObj);
        }

        if (S.type === TYPE.AFFINITY && S.priority === PRIORITY.PREFERRED) {
          out.podAffinity.preferredDuringSchedulingIgnoredDuringExecution.push(preferredObj);
        }

        if (S.type === TYPE.ANTI_AFFINITY && S.priority === PRIORITY.REQUIRED) {
          out.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution.push(requiredObj);
        }

        if (S.type === TYPE.ANTI_AFFINITY && S.priority === PRIORITY.PREFERRED) {
          out.podAntiAffinity.preferredDuringSchedulingIgnoredDuringExecution.push(preferredObj);
        }
      });
      const parseObj = { ...Ember.get(this, 'vmAffinity')
      };

      if (!this.isEmptyObject(Ember.get(out, 'podAffinity') || {})) {
        Ember.set(parseObj, 'podAffinity', Ember.get(out, 'podAffinity'));
      } else {
        delete parseObj.podAffinity;
      }

      if (!this.isEmptyObject(Ember.get(out, 'podAntiAffinity') || {})) {
        Ember.set(parseObj, 'podAntiAffinity', Ember.get(out, 'podAntiAffinity'));
      } else {
        delete parseObj.podAntiAffinity;
      }

      Ember.set(this, 'config.vmAffinity', this.isEmptyObject(parseObj) ? '' : AWS.util.base64.encode(JSON.stringify(parseObj)));
      Ember.set(this, 'vmAffinity', parseObj);
    },

    validateScheduling(errors) {
      if (Ember.get(this, 'podSchedulings').find(S => !S.topologyKey)) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('nodeDriver.harvester.scheduling.input.topology.label')
        }));
      }

      const nodeHasMissingKey = Ember.get(this, 'nodeSchedulings').find(S => {
        return Ember.get(S, 'nodeSelectorTerms.matchExpressions').find(M => !Ember.get(M, 'key'));
      });
      const podHasMissingKey = Ember.get(this, 'podSchedulings').find(S => {
        return Ember.get(S, 'labelSelector.matchExpressions').find(M => !Ember.get(M, 'key'));
      });

      if (nodeHasMissingKey || podHasMissingKey) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('formNodeRequirement.key.label')
        }));
      }
    },

    isValidMac(value) {
      return /^[A-Fa-f0-9]{2}(-[A-Fa-f0-9]{2}){5}$|^[A-Fa-f0-9]{2}(:[A-Fa-f0-9]{2}){5}$/.test(value);
    },

    validateDiskAndNetwork(errors) {
      const disks = Ember.get(this, 'disks');
      disks.forEach(disk => {
        if (Object.prototype.hasOwnProperty.call(disk, 'imageName') && !disk.imageName) {
          errors.push(this.intl.t('generic.required', {
            key: this.intl.t('nodeDriver.harvester.imageName.label')
          }));
        }

        if (Object.prototype.hasOwnProperty.call(disk, 'storageClassName') && !disk.storageClassName) {
          errors.push(this.intl.t('generic.required', {
            key: this.intl.t('nodeDriver.harvester.storageClass.label')
          }));
        }

        if (!disk.size) {
          errors.push(this.intl.t('generic.required', {
            key: this.intl.t('nodeDriver.harvester.diskSize.label')
          }));
        }

        if (!disk.size) {
          errors.push(this.intl.t('generic.required', {
            key: this.intl.t('nodeDriver.harvester.diskSize.label')
          }));
        }
      });
      const interfaces = Ember.get(this, 'interfaces');
      interfaces.forEach(_interface => {
        if (!_interface.networkName) {
          errors.push(this.intl.t('generic.required', {
            key: this.intl.t('nodeDriver.harvester.networkName.label')
          }));
        }

        if (_interface.macAddress && !this.isValidMac(_interface.macAddress)) {
          errors.push(this.intl.t('generic.required', {
            key: this.intl.t('nodeDriver.harvester.network.macFormat')
          }));
        }
      });
    }

  });

  _exports.default = _default;
});