define("shared/components/save-cancel/component", ["exports", "shared/components/save-cancel/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['text-center', 'pt-20', 'pb-20'],
    classNamesBindings: ['forFooter: footer-actions'],
    attributeBindings: ['data-testid'],
    cancelColor: 'bg-transparent',
    cancelDisabled: false,
    cancelLabel: 'saveCancel.cancel',
    createLabel: 'saveCancel.create',
    editLabel: 'saveCancel.edit',
    editing: null,
    forFooter: true,
    saveColor: 'bg-primary',
    saveDisabled: false,
    saved: false,
    savedColor: 'bg-success',
    savedLabel: 'saveCancel.saved',
    saving: false,
    savingLabel: 'saveCancel.saving',
    savingColor: 'bg-primary',
    actions: {
      save() {
        // The save action must take a callback and call it when done (whether it succeded or not)
        // to update the state of the button.
        //
        Ember.setProperties(this, {
          saving: true,
          saved: false
        });

        if (this.save) {
          const cb = success => {
            if (this.isDestroyed || this.isDestroying) {
              return;
            }

            Ember.setProperties(this, {
              saving: false,
              saved: success === true
            });
          };

          if (typeof this.save === 'string') {
            this.sendAction('save', cb);
          } else {
            this.save(cb);
          }
        }
      },

      cancel() {
        if (this.cancel) {
          this.cancel();
        }
      },

      doNothing() {}

    },
    savedChanged: Ember.observer('saved', function () {
      if (Ember.get(this, 'saved')) {
        Ember.run.later(this, () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'saved', false);
        }, 5000);
      }
    }),
    btnLabel: Ember.computed('saved', 'editing', 'savedLabel', 'editLabel', 'createLabel', function () {
      if (Ember.get(this, 'saved')) {
        return Ember.get(this, 'savedLabel');
      } else if (Ember.get(this, 'editing')) {
        return Ember.get(this, 'editLabel');
      } else {
        return Ember.get(this, 'createLabel');
      }
    })
  });

  _exports.default = _default;
});