define("global-admin/cluster-templates/new-revision/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "109wJC0H",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"clusterTemplatesPage.newRevision.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"cru-cluster-template\",[],[[\"@clusterTemplate\",\"@clusterTemplateRevision\",\"@updateTemplateId\",\"@clusterTemplateRevisionId\",\"@psps\",\"@psacs\",\"@mode\",\"@cancel\",\"@done\"],[[34,1,[\"clusterTemplate\"]],[34,1,[\"clusterTemplateRevision\"]],[30,[36,2],[[32,0],\"updateTemplateId\"],null],[34,1,[\"clusterTemplateRevisionId\"]],[34,1,[\"psps\"]],[34,1,[\"psacs\"]],\"edit\",[30,[36,2],[[32,0],\"cancel\"],null],[30,[36,2],[[32,0],\"done\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"action\"]}",
    "moduleName": "global-admin/cluster-templates/new-revision/template.hbs"
  });

  _exports.default = _default;
});