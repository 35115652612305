define("global-admin/cluster-templates/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal'),
    selection: [],
    canCompare: false,
    actions: {
      selectionChanged(s) {
        Ember.set(this, 'selection', s || []);
        Ember.set(this, 'canCompare', s.length === 2);
      },

      showDiff() {
        const compare = Ember.get(this, 'selection');
        this.get('modalService').toggleModal('modal-view-template-diff', compare);
      }

    }
  });

  _exports.default = _default;
});