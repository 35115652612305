define("shared/components/form-agent-config/component", ["exports", "shared/components/form-agent-config/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    mode: 'new',
    cluster: {},
    // cluster or fleet agent deployment
    type: 'cluster',
    useDefaultAffinity: true,
    attributeBindings: ['data-testid'],

    init() {
      this._super(...arguments);

      if (this.overrideAffinity && !Ember.isEqual(this.defaultAffinity, this.overrideAffinity)) {
        this.useDefaultAffinity = false;
      }
    },

    actions: {
      updateLimits(val) {
        if (!val.cpu && !val.memory) {
          this.updateResourceRequirement('limits', null);
        } else {
          this.updateResourceRequirement('limits', val);
        }
      },

      updateRequests(val) {
        if (!val.cpu && !val.memory) {
          this.updateResourceRequirement('requests', null);
        } else {
          this.updateResourceRequirement('requests', val);
        }
      }

    },
    agentObserver: Ember.observer('agentConfig.overrideResourceRequirements', 'agentConfig.appendTolerations', 'agentConfig.overrideAffinity', function () {
      const agentConfig = Ember.get(this, 'agentConfig') || {};
      const agentKey = Ember.get(this, 'agentKey');

      if (Object.keys(agentConfig).length) {
        Ember.set(this.cluster, agentKey, agentConfig);
      } else if (this.cluster[agentKey]) {
        delete this.cluster[agentKey];
      }
    }),
    useDefaultAffinityObserver: Ember.observer('useDefaultAffinity', function () {
      if (!this.useDefaultAffinity) {
        this.set('overrideAffinity', this.defaultAffinity);
      } else {
        // default rules will be applied by BE if this field is empty
        this.set('overrideAffinity', null);
      }
    }),
    editing: Ember.computed('mode', function () {
      const {
        mode
      } = this;
      return mode === 'new' || mode === 'edit';
    }),
    agentKey: Ember.computed('type', function () {
      return `${this.type}AgentDeploymentCustomization`;
    }),
    defaultSettingId: Ember.computed('type', function () {
      return `${this.type}-agent-default-affinity`;
    }),
    defaultAffinity: Ember.computed('defaultSettingId', function () {
      if (!this.defaultSettingId) {
        return null;
      }

      const setting = this.globalStore.getById('setting', this.defaultSettingId);

      if (setting) {
        try {
          return JSON.parse(setting.value);
        } catch (e) {
          console.error('error parsing affinity default value: ', e);
        }
      }

      return null;
    }),
    agentConfig: Ember.computed('cluster', 'agentKey', function () {
      const agentKey = Ember.get(this, 'agentKey');
      return Ember.get(this.cluster, agentKey) || {};
    }),
    overrideResourceRequirements: Ember.computed('agentConfig.overrideResourceRequirements.{requests,limits}', {
      get() {
        return Ember.get(this, 'agentConfig.overrideResourceRequirements') || {};
      },

      set(key, val = {}) {
        if (val.limits || val.requests) {
          Ember.set(this, 'agentConfig.overrideResourceRequirements', val);
        } else if (this.agentConfig.overrideResourceRequirements) {
          Ember.set(this, 'agentConfig.overrideResourceRequirements', null);
          this.notifyPropertyChange('agentConfig');
        }

        return val;
      }

    }),
    limits: Ember.computed('overrideResourceRequirements.limits', function () {
      return Ember.get(this, 'overrideResourceRequirements.limits') || {};
    }),
    requests: Ember.computed('overrideResourceRequirements.requests', function () {
      return Ember.get(this, 'overrideResourceRequirements.requests') || {};
    }),
    appendTolerations: Ember.computed('agentConfig.appendTolerations', {
      get() {
        return Ember.get(this, 'agentConfig.appendTolerations') || [];
      },

      set(key, val = []) {
        if (val.length) {
          Ember.set(this, 'agentConfig.appendTolerations', val);
        } else if (this.agentConfig.appendTolerations) {
          Ember.set(this, 'agentConfig.appendTolerations', null);
          this.notifyPropertyChange('agentConfig');
        }

        return val;
      }

    }),
    overrideAffinity: Ember.computed('agentConfig.overrideAffinity', {
      get() {
        return Ember.get(this, 'agentConfig.overrideAffinity');
      },

      set(key, val) {
        var _this$agentConfig;

        if (val) {
          Ember.set(this.agentConfig, 'overrideAffinity', val);
        } else if ((_this$agentConfig = this.agentConfig) !== null && _this$agentConfig !== void 0 && _this$agentConfig.overrideAffinity) {
          Ember.set(this, 'agentConfig.overrideAffinity', null);
          this.notifyPropertyChange('agentConfig');
        }

        return val;
      }

    }),

    updateResourceRequirement(type, val) {
      const neu = { ...this.overrideResourceRequirements
      };

      if (val) {
        neu[type] = val;
      } else if (neu[type]) {
        delete neu[type];
      }

      this.set('overrideResourceRequirements', neu);
    }

  });

  _exports.default = _default;
});