define("shared/components/modal-confirm-imported-upgrade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hSLq3g35",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container-header-text\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"managedImportClusterInfo.pspUpgradeWarning.modalTitle\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"managedImportClusterInfo.pspUpgradeWarning.k3sWarning\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"managedImportClusterInfo.pspUpgradeWarning.rke2Warning\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,3],[[32,0],\"confirm\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"managedImportClusterInfo.pspUpgradeWarning.proceed\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-transparent\"],[24,4,\"button\"],[4,[38,3],[[32,0],\"cancel\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"generic.cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"isK3sCluster\",\"if\",\"action\"]}",
    "moduleName": "shared/components/modal-confirm-imported-upgrade/template.hbs"
  });

  _exports.default = _default;
});