define("shared/components/cluster-driver/driver-linodelke/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-linodelke/template"], function (_exports, _clusterDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NODE_POOL_HEADERS = [{
    name: 'label',
    sort: ['label'],
    translationKey: 'clusterNew.linodelke.nodePoolConfig.label'
  }, {
    name: 'priceMonthly',
    sort: ['price.monthly'],
    translationKey: 'clusterNew.linodelke.nodePoolConfig.monthly'
  }, {
    name: 'priceHourly',
    sort: ['price.hourly'],
    translationKey: 'clusterNew.linodelke.nodePoolConfig.hourly'
  }, {
    name: 'ram',
    sort: ['memoryGb'],
    translationKey: 'clusterNew.linodelke.nodePoolConfig.ram'
  }, {
    name: 'cpus',
    sort: ['vcpus'],
    translationKey: 'clusterNew.linodelke.nodePoolConfig.cpus'
  }, {
    name: 'storage',
    sort: ['diskGb'],
    translationKey: 'clusterNew.linodelke.nodePoolConfig.storage'
  }, {
    name: 'count',
    sort: ['count'],
    translationKey: 'clusterNew.linodelke.nodePoolConfig.count'
  }];

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    linode: Ember.inject.service(),
    layout: _template.default,
    configField: 'lkeEngineConfig',
    step: 1,
    lanChanged: null,
    refresh: false,
    sortBy: '',
    descending: false,
    nodePoolHeaders: NODE_POOL_HEADERS,
    // This value is currently not returned by the Linode API
    highAvailabilityMonthlyPrice: 60.0,

    init() {
      this._super(...arguments);

      let config = Ember.get(this, 'cluster.lkeEngineConfig');
      let configField = Ember.get(this, 'configField');
      Ember.setProperties(this, {
        // We should store the previous state of HA to disallow downgrades
        'highAvailability': config ? config.highAvailability : false,
        'newTag': '',
        'selectedNodePoolType': '',
        'selectedNodePoolObj': {},
        'selectedNodePoolList': this.prefillSelectedNodePoolList()
      });

      if (!config) {
        config = this.get('globalStore').createRecord({
          type: configField,
          name: '',
          label: '',
          description: '',
          accessToken: '',
          region: 'us-central',
          kubernetesVersion: '',
          highAvailability: false,
          tags: [],
          nodePools: []
        });
        Ember.set(this, 'cluster.lkeEngineConfig', config);
      }
    },

    actions: {
      verifyAccessToken(cb) {
        const auth = {
          token: Ember.get(this, 'cluster.lkeEngineConfig.accessToken')
        };
        let errors = [];
        const intl = Ember.get(this, 'intl');

        if (!auth.token) {
          errors.push(intl.t('clusterNew.linodelke.accessToken.required'));
          Ember.set(this, 'errors', errors);
          cb(false);
        } else {
          Ember.RSVP.hash({
            regions: this.linode.request(auth, 'regions'),
            nodeTypes: this.linode.request(auth, 'linode/types'),
            k8sVersions: this.linode.request(auth, 'lke/versions')
          }).then(responses => {
            Ember.setProperties(this, {
              errors: [],
              step: 2,
              regions: responses.regions.data.filter(region => region.status === 'ok' && region.capabilities.includes('Kubernetes')),
              nodeTypes: responses.nodeTypes.data.filter(type => ['standard', 'highmem', 'dedicated'].includes(type.class)),
              k8sVersions: responses.k8sVersions.data
            }); // default to latest version of LKE

            Ember.set(this, 'config.kubernetesVersion', responses.k8sVersions.data[0].id);
            cb(true);
          }).catch(err => {
            if (err && err.body && err.body.errors && err.body.errors[0]) {
              errors.push(`Error received from Linode: ${err.body.errors[0].reason}`);
            } else {
              errors.push(`Error received from Linode`);
            }

            this.setProperties({
              errors
            });
            cb(false);
          });
        }
      },

      verifyClusterConfig(cb) {
        // verify if tags are not null
        // if null replace with empty array
        const tags = Ember.get(this, 'cluster.lkeEngineConfig.tags');

        if (!tags) {
          Ember.set(this, 'cluster.lkeEngineConfig.tags', []);
        }

        Ember.set(this, 'step', 3);
        cb(true);
      },

      createCluster(cb) {
        if (this.verifyNodePoolConfig()) {
          this.send('driverSave', cb);
        } else {
          cb(false);
        }
      },

      updateCluster(cb) {
        if (this.verifyNodePoolConfig()) {
          this.send('driverSave', cb);
        } else {
          cb(false);
        }
      },

      cancelFunc(cb) {
        // probably should not remove this as its what every other driver uses to get back
        Ember.get(this, 'router').transitionTo('global-admin.clusters.index');
        cb(true);
      },

      // for tags
      addNewTag() {
        const tags = Ember.get(this, 'cluster.lkeEngineConfig.tags') || [];
        const newTag = Ember.get(this, 'newTag');

        if (newTag) {
          tags.pushObject(newTag);
          Ember.set(this, 'cluster.lkeEngineConfig.tags', tags);
          Ember.set(this, 'newTag', '');
        }
      },

      deleteTag(idx) {
        const tags = Ember.get(this, 'cluster.lkeEngineConfig.tags') || [];
        Ember.set(this, 'cluster.lkeEngineConfig.tags', tags.filter((tag, index) => index !== idx));
      },

      // for node pools
      addSelectedNodePool() {
        const selectedNodePoolObj = Ember.get(this, 'selectedNodePoolObj');
        const selectedNodePoolList = Ember.get(this, 'selectedNodePoolList');

        if (selectedNodePoolObj.id) {
          // add to list
          selectedNodePoolList.pushObject(selectedNodePoolObj); // clear selected

          Ember.set(this, 'selectedNodePoolType', '');
          Ember.set(this, 'selectedNodePoolObj', {});
        }
      },

      deleteNodePool(id) {
        const selectedNodePoolList = Ember.get(this, 'selectedNodePoolList');
        Ember.set(this, 'selectedNodePoolList', selectedNodePoolList.filter(n => n.id !== id));
      }

    },
    // For languages
    clusterNameChanged: Ember.observer('cluster.name', function () {
      const clusterName = Ember.get(this, 'cluster.name');
      Ember.setProperties(this, {
        'cluster.lkeEngineConfig.name': clusterName,
        'cluster.lkeEngineConfig.label': clusterName
      });
    }),
    clusterDescriptionChanged: Ember.observer('cluster.description', function () {
      const clusterDescription = Ember.get(this, 'cluster.description');
      Ember.set(this, 'cluster.lkeEngineConfig.description', clusterDescription);
    }),
    setSelectedNodePoolObj: Ember.observer('selectedNodePoolType', async function () {
      const nodePoolTypes = await Ember.get(this, 'nodeTypes');
      const selectedNodePoolType = Ember.get(this, 'selectedNodePoolType');

      if (selectedNodePoolType) {
        const ans = nodePoolTypes.find(np => np.id === selectedNodePoolType);
        Ember.set(this, 'selectedNodePoolObj', { ...ans,
          count: 1,
          memoryGb: ans.memory / 1024,
          diskGb: ans.disk / 1024
        });
      } else {
        Ember.set(this, 'selectedNodePoolObj', {});
      }
    }),
    setNodePools: Ember.observer('selectedNodePoolList.@each.count', function () {
      const selectedNodePoolList = Ember.get(this, 'selectedNodePoolList');
      const nodePools = selectedNodePoolList.map(np => {
        return `${np.id}=${np.count}`;
      });
      Ember.set(this, 'cluster.lkeEngineConfig.nodePools', nodePools);
    }),
    // to prefil selected node pool list for edit mode
    prefillSelectedNodePoolListObserver: Ember.observer('nodeTypes.[]', function () {
      this.prefillSelectedNodePoolList();
    }),
    // Any computed properties or custom logic can go here
    // for region choises
    regionChoises: Ember.computed('regions', async function () {
      const ans = await Ember.get(this, 'regions');
      return ans.map(e => {
        return {
          label: e.id,
          value: e.id
        };
      });
    }),
    // for kubernetes version
    k8sVersionChoises: Ember.computed('k8sVersions.[]', function () {
      const k8sVersions = Ember.get(this, 'k8sVersions');
      return k8sVersions.map(v => {
        return {
          label: v.id,
          value: v.id
        };
      });
    }),
    // for node pool choises
    nodePoolChoises: Ember.computed('nodeTypes.[]', 'selectedNodePoolList.[]', async function () {
      const intl = Ember.get(this, 'intl');
      const ans = await Ember.get(this, 'nodeTypes');
      const filteredAns = ans.filter(np => {
        // filter out the already selected node pools
        const selectedNodePoolList = Ember.get(this, 'selectedNodePoolList');
        const fnd = selectedNodePoolList.find(snp => snp.id === np.id);

        if (fnd) {
          return false;
        } else {
          return true;
        }
      }).map(np => {
        return {
          label: np.label,
          value: np.id
        };
      });
      return [{
        label: intl.t('clusterNew.linodelke.nodePools.placeholder'),
        value: ''
      }, ...filteredAns];
    }),

    // Add custom validation beyond what can be done from the config API schema
    validate() {
      // Get generic API validation errors
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'cluster.name')) {
        errors.push('Name is required');
      } // Add more specific errors
      // Check something and add an error entry if it fails:
      // if ( parseInt(get(this, 'config.memorySize'), defaultRadix) < defaultBase ) {
      //   errors.push('Memory Size must be at least 1024 MB');
      // }
      // Set the array of errors for display,
      // and return true if saving should continue.


      if (Ember.get(errors, 'length')) {
        Ember.set(this, 'errors', errors);
        return false;
      } else {
        Ember.set(this, 'errors', null);
        return true;
      }
    },

    verifyNodePoolConfig() {
      const intl = Ember.get(this, 'intl');
      const selectedNodePoolList = Ember.get(this, 'selectedNodePoolList');
      const errors = [];

      if (selectedNodePoolList.length === 0) {
        errors.push(intl.t('clusterNew.linodelke.nodePools.required'));
        Ember.set(this, 'errors', errors);
        return false;
      } else {
        const fnd = selectedNodePoolList.find(np => np.count <= 0);

        if (fnd) {
          errors.push(intl.t('clusterNew.linodelke.nodePools.countError'));
          Ember.set(this, 'errors', errors);
          return false;
        }

        return true;
      }
    },

    async prefillSelectedNodePoolList() {
      const nodePools = Ember.get(this, 'cluster.lkeEngineConfig.nodePools');
      const nodePoolTypes = await Ember.get(this, 'nodeTypes');

      if (nodePools && nodePools.length) {
        Ember.set(this, 'selectedNodePoolList', nodePools.map(np => {
          const [npId, cnt] = np.split('=');
          const fnd = nodePoolTypes.find(npt => npt.id === npId);

          if (fnd) {
            return { ...fnd,
              count: cnt
            };
          } else {
            return {
              id: npId,
              count: cnt,
              label: npId
            };
          }
        }));
      } else {
        Ember.set(this, 'selectedNodePoolList', []);
      }
    }

  });

  _exports.default = _default;
});